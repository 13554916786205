import React from "react";
import Locations from "./components/locations/location";
import HourlyTruckingCalculator from "./components/calculators/hourlyTrucking";
import PavingCalculator from "./components/calculators/paving";
import TruckCycleTimeCalculator from "./components/calculators/truckCycleTimes";
import ProductCompareTable from "./components/specifications/compare-table";
import Svg from "./components/svgs/svg";
import ElasticProducts from "./components/elasticProducts/elasticProducts";
import FacilityListing from "./components/facilities/facility-listing";

const whereToBuy = document.getElementById('where-to-buy');
const elasticProducts = document.getElementById('elastic-products');
const hourlyTruckingCalculator = document.getElementById('hourly-trucking-calculator');
const pavingCalculator = document.getElementById('paving-calculator');
const cycleTimeCalculator = document.getElementById('cycle-time-calculator');
const compareTable = document.getElementById('product-compare-table');
const facilityMap = document.getElementById('facility-map');
const App = (props) => {
    return (
        <div>
            <Svg></Svg>
			{whereToBuy && <Locations></Locations>}
            {elasticProducts && <ElasticProducts></ElasticProducts>}
            {cycleTimeCalculator && <TruckCycleTimeCalculator></TruckCycleTimeCalculator>}
			{pavingCalculator && <PavingCalculator></PavingCalculator>}
            {hourlyTruckingCalculator && <HourlyTruckingCalculator></HourlyTruckingCalculator>}
            {compareTable && <ProductCompareTable></ProductCompareTable>}
            {facilityMap && <FacilityListing></FacilityListing>}
        </div>
    )
}; 

export default App;