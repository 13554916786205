import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme} from 'react-loading-skeleton'
import { formatUnitText } from "../helpers/helpers";
import 'react-loading-skeleton/dist/skeleton.css'

// facets are the initial options we retrieve on page load (or on toggling units)
// live facets are the ones available in the current result set from elastic. We use this to determine which ones to disable.
const ProductFacets = ({ units, params, setParams, facets, liveFacets, setSelected, selected }) => {
    const [filterStatus, setFilterStatus] = useState(false);
    const isRange = (option) => {
        return option.min || option.max;
    }

    const changeValue = (facet, option) => {
        const newParams = { ...params, page: 1 };
        const newFilter = { field: facet.field, key: option.key };
        if (facet.isSpecFacet) {
            const existing = newParams.specFilters.find(f => f.field === newFilter.field && f.key === newFilter.key);
            if (existing) {
                newParams.specFilters = newParams.specFilters.filter(f => !(f.field === newFilter.field && f.key === newFilter.key));
            } else {
                if(isRange(option)){
                    newFilter.range = { min: option.min, max: option.max };
                }
                newParams.specFilters.push(newFilter);
            }
        } else {
            newParams.filters = [newFilter];
        }
        setParams(newParams);
    }

    const resetFilters = () => {
        const newParams = { ...params, specFilters: [], filters: [], page: 1 };
        setParams(newParams);
    }

    const isDisabled = (option, field) => {
        // total of 0 are facets that wont display results
        if (option.total === 0) {
            return true;
        }
        const optionKey = option.key;
        // if the option doesnt exist in the live facet
        if(!liveFacets?.some(facet => facet.options.some(o => o.key === optionKey) && facet.field == field)){
            return true;
        }
        return false;
    }

    // for ranges, need to convert them using the min/max values so they dont have "asterisk" in it.
    const formatOptionKey = (option) => {
        if (option.min && option.max) {
            const range = `${option.min} - ${option.max}`;
            return range.toLocaleString();
        } else if(option.min) {
            // when no max, we are at the highest range
            const range = `${option.min}+`;
            return range.toLocaleString();
        } else if(option.max) {
            // lowest range
            const range = `0 - ${option.max}`
            return range.toLocaleString();
        } else {
            return option.key.toLocaleString();
        }
    }

    useEffect(() => {
        if(filterStatus) {
            document.body.classList.add('filter-active');
        } else {
            document.body.classList.remove('filter-active');
        }
    }, [filterStatus]);

    return (
        <>
            <div className={`product-filter ${filterStatus}`}>
				<button
					type="button"
					className="product-filter__close"
					aria-label="close filter"
					onClick={() => setFilterStatus(false)}
				></button>
				<div className="product-filter__header">
					<div className="product-filter__filter">
						<svg
							className="product-filter__icon product-filter__icon--secondary"
							viewBox="0 0 16 16"
						>
							<use xlinkHref="#icon-filter"></use>
						</svg>
						Filter
					</div>
					<button type="button" className="product-filter__reset" onClick={() => resetFilters()}>
						<svg
							className="product-filter__icon"
							viewBox="0 0 16 16"
						>
							<use xlinkHref="#icon-reset"></use>
						</svg>
						Reset Filter
					</button>
				</div>
                <ul className="product-filter__list">
                    {
                        !facets && Array(4).fill().map(() => (
                            <li className="product-filter__item">
                                <h3 className="product-filter__type">
                                    <Skeleton height={30}></Skeleton>
                                </h3>
                                <ul className="product-filter__filter-list">
                                    {
                                        Array(5).fill().map(() => (
                                            <li className="product-filter__option">
                                                <Skeleton height={30}></Skeleton>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>                        
                        ))
                    }
                    {facets && facets.map((facet,index) => <>
                        <li className="product-filter__item" key={index}>
                            <h3 className="product-filter__type">{facet.label === "componentTypes" ? "Component Type" : facet.label}</h3>
                            <ul className="product-filter__filter-list">
                                {facet.options.sort((a,b) => a.key - b.key).map((option,subIndex) => {
                                    if(facet.field === "componentTypes") {
                                        return (
                                        <li className={`product-filter__option`} key={subIndex}>
                                            <label class="form__label form__label--flex-inline">
                                                <input class="form__input-radio" type="radio" name={facet.field} value={option.key} onChange={() => changeValue(facet,option)} checked={params.filters.find(s => s.field === facet.field && s.key === option.key)}/>
                                                <span class="form__pseudo-radio"></span>
                                                <span className="form__checkbox-text">
                                                    {" "}{option.key === true ? "True" : option.key === false ? "False" : formatOptionKey(option)}
                                                </span>
                                            </label>
                                        </li>   
                                        )
                                    } else {
                                        return (
                                            <li className={`product-filter__option${isDisabled(option, facet.field) ? " disabled": ""}`} key={subIndex}>
                                                <label className="form__label form__label--flex-inline">
                                                    <input type="checkbox" className="form__input-checkbox form__input-checkbox--secondary" disabled={isDisabled(option, facet.field)} value={option.key} name={facet.field} checked={params.filters.find(s => s.field === facet.field && s.key === option.key) || params.specFilters.find(s => s.field === facet.field && s.key === option.key) || false} onChange={() => changeValue(facet, option)} />
                                                    <span className="form__pseudo-checkbox"></span>
                                                    <span className="form__checkbox-text">
                                                        {" "}{option.key === true ? "True" : option.key === false ? "False" : formatOptionKey(option)}
                                                        
                                                        {facet.units && <>
                                                            {" "}
                                                            {
                                                                units === "metric" &&
                                                                    <>{facet.units.metric === "m3" ? <span>m<sup>3</sup></span> : formatUnitText(facet.units.metric)}</>
                                                            }
                                                            {
                                                                units === "imperial" &&
                                                                    <>{facet.units.imperial === "yd3" ? <span>yd<sup>3</sup></span> : formatUnitText(facet.units.imperial)}</>
                                                            }
                                                        </>}
                                                    </span>
                                                </label>
                                            </li>
                                        )
                                    }
                                }
                                    
                                )}
                            </ul>
                        </li>
                    </>)}
                </ul>
				<button
					type="button"
					className="button product-filter__button-apply"
				onClick={() => setFilterStatus(false)}>
					Apply Filter
				</button>
			</div>
            {/* <span className="product-cards__results">32 Results Found</span> */}
			<button
				type="button"
				className="product-filter__filter product-filter__filter--mobile"
				onClick={() => setFilterStatus(true)}
			>
				<svg
					className="product-filter__icon product-filter__icon--secondary"
					viewBox="0 0 16 16"
				>
					<use xlinkHref="#icon-filter"></use>
				</svg>
				Filter
			</button>
        </>
        
    )
}

export default ProductFacets;