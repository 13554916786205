import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import Dealer from "./dealer";
import Contact from "./contact";

const Locations = (props) => {
	const containerElement = document.getElementById("where-to-buy");
	const [toggleStatus, setToggleStatus] = useState(true);
	const [parameterType, setParameterType] = useState("zipcode");
	const [productType, setProductType] = useState("");
	const [errorStatus, setErrorStatus] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [province, setProvince] = useState("Quebec");
	const [country, setCountry] = useState("United States");
	const [dealer, setDealer] = useState({});
	const [noResults, setNoResults] = useState("initial");
	const [countries, setCountries] = useState([
		"United States",
		"Canada",
		"South Africa",
		"Australia",
		"Mexico",
		"Bulgaria",
	]);
	const [productLines, setProductLines] = useState({
		"6ee5c2a9-3fd3-42f1-ad4f-e9b43c6e993b": "Drum Chippers",
		"7bffb082-23c0-47d0-b25c-1f667a4b3da3": "Mobile Crushing & Screening",
		"15a0008d-0b10-48c0-ab78-13b282888435": "Soil Stabilizers",
		"6026d30a-8023-45a9-b8fb-bedefb665c11": "Mobile Mining Equipment",
		"230123b5-2b95-476c-b950-6d46eb02e9c2": "Crushing & Screening [Osborn]",
		"fd27bcc1-a2ad-420e-a0d3-9ea5b0f88740": "Highway Class Pavers",
		"bfeab2ac-b2c8-46f2-9a07-83111c35c7ef": "Washing & Classifying",
	});
	const [provinces, setProvinces] = useState();
	const zipCodeInput = useRef(null);


	// when coming from product detail page
	// the query param for id can be prepopulated
	// if the product has an aorproductline field value
	const setDefaultProducType = () => {
		const params = new URLSearchParams(window.location.search);
		const productId = params.get('productType');
		if (productId) {
			setProductType(productId);
		} else {
			setProductType(Object.keys(productLines)[0]);
		}
	}

	const toggleFilter = (e) => {
		setToggleStatus((status) => !status);
	};

	const handleCountryChange = (e) => {
		const value = e.target.value;
		setCountry(value);
		if (value === "United States") {
			setParameterType("zipcode");
		} else if (value === "Canada" || value === "Mexico" || value === "South Africa" || value === "Australia") {
			setParameterType("province");
			setProvince(provinces.find(p => p.CountryName === value)?.Provinces[0]);
		} else {
			setParameterType("default");
		}
	};

	const handleProvinceChange = (e) => {
		const value = e.target.value;
		setProvince(value);
	};

	const handleZipCodeUpdate = (e) => {
		const value = e.target.value;
		setZipCode(value);
	};

	const updateProductType = (e) => {
		const value = e.target.value;
		setProductType(value);
	};

	const searchLocations = () => {
		// U.S. requires zip code and product selected
		// Canada requires province and product selected
		// Other countries require product select
		if (parameterType === "zipcode" && zipCode.length !== 5) {
			setErrorStatus(parameterType);
			zipCodeInput.current.focus();
		} else {
			setErrorStatus("");
			requestLocations();
		}
	};

	const requestLocations = () => {
		// Can send 3 different params
		// United States => Product and Zip
		// Canada => Product and Province
		// Any Other Country = > Product and Country Name
		const url = `/api/dealers/find?productLineId=${productType}&zipCode=${zipCode}`;
		const url2 = `/api/dealers/find?productLineId=${productType}&countryName=${country}&provinceName=${province}`;
		const url3 = `/api/dealers/find?productLineId=${productType}&countryName=${country}`;
		const urlToSend =
			country === "United States"
				? url
				: parameterType === "province" ? url2 :
					url3;
				
		fetch(urlToSend)
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					setNoResults(true);
				}
			})
			.then((data) => {
				renderResults(data);
			})
			.catch((error) => {
				setNoResults(true);
			});
	};

	const scrollToResults = () => {
		const search = document.getElementById("dealer-search");
		search.scrollIntoView({
			behavior: "smooth",
		});
	};

	const renderResults = (data) => {
		if (data) {
			setDealer(data);
			setNoResults(false);
		}
		scrollToResults();
	};

	useEffect(() => {
		// Set country, province and products
		if (window.astec) {
			setCountries(window.astec.countries);
			setProductLines(window.astec.productLines);
			setProvinces(window.astec.provinces);
			setDefaultProducType();
		}
	}, []);

	useEffect(() => {
		setDefaultProducType();
	}, [productLines]);

	return ReactDOM.createPortal(
		<div className="locations">
			<div className="locations__header">
				<div className="locations__select-wrapper select-wrapper">
					<label
						htmlFor="location-country"
						className="visually-hidden"
					>
						Select a country
					</label>
					<select
						id="location-country"
						className="locations__select form__select"
						onChange={handleCountryChange}
						value={country}
						required
					>
						{countries.map((item, index) => {
							return (
								<option value={item} key={index}>
									{item}
								</option>
							);
						})}
					</select>
				</div>

				{parameterType === "province" ? (
					<div className="locations__select-wrapper select-wrapper">
						<label
							htmlFor="location-province"
							className="visually-hidden"
						>
							Select a Province
						</label>
						<select
							id="location-province"
							className="locations__select form__select"
							onChange={handleProvinceChange}
							required
						>
							{provinces.find(p => p.CountryName === country)?.Provinces?.map((item, index) => {
								return (
									<option value={item} key={index}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
				) : parameterType === "zipcode" ? (
					<label className="locations__label">
						<span className="visually-hidden">Zip Code</span>
						<input
							type="text"
							className="locations__input"
							placeholder="ZIP/Postal Code"
							maxLength="5"
							ref={zipCodeInput}
							onChange={handleZipCodeUpdate}
							required
						></input>
						<div className="locations__error" aria-live="polite">
							{errorStatus === "zipcode" && (
								<div className="locations__error-message">
									Zip code field is required
								</div>
							)}
						</div>
					</label>
				) : (
					""
				)}
			</div>
			<div className="locations__filter">
				<h2 className="locations__filter-heading">
					<button
						type="button"
						className={`locations__toggle button ${toggleStatus}`}
						aria-pressed={toggleStatus}
						onClick={toggleFilter}
					>
						Product Type
					</button>
				</h2>

				<form className={`locations__filter-form ${toggleStatus}`}>
					<fieldset className="locations__fieldset">
						<legend className="visually-hidden">
							Select a product type
						</legend>
						{Object.keys(productLines).map((item, index) => {
							return (
								<label
									className="locations__filter-label"
									key={item}
								>
									<input
										type="radio"
										className="locations__input-radio"
										value={item}
										name="product-line"
										checked={item === productType}
										onChange={updateProductType}
									/>
									{productLines[item]}
								</label>
							);
						})}
					</fieldset>
				</form>
			</div>
			<button
				id="dealer-search"
				type="button"
				className="button locations__search"
				aria-label="search for locations that match your input requirements"
				onClick={searchLocations}
			>
				Search
			</button>

			<div aria-live="polite" id="dealer-results">
				{noResults === true ? (
					<p className="locations__no-results">No results found.</p>
				) : dealer.IsAstecDirect ? (
					<Contact info={dealer.Locations[0]}></Contact>
				) : noResults !== "initial" ? (
					<Dealer info={dealer} productLines={productLines}></Dealer>
				) : (
					""
				)}
			</div>
		</div>,
		containerElement
	);
};
export default Locations;
