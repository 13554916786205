import React, {useState} from "react";
import { formatUnitText } from "../helpers/helpers";

const ProductCard = ({ units, product, facets }) => {

    let specs = Object.values(product.models.flatMap(m => m.specs).reduce(function (r, a) {
        r[a.field] = r[a.field] || { ...a, values: [] };
        delete r[a.field].value;
        if (!r[a.field].values.includes(a.value)) {
            r[a.field].values.push(a.value);
        }
        return r;
    }, Object.create(null)));   

    const isComponentType = facets.some((facet) => facet.label === "componentTypes");

    specs = specs.map(s => {
        if (s.type === 'NUMBER') {
            s.values = s.values.sort((a, b) => +a - +b);
        } else if (s.type === 'MEASUREMENT') {
            s.values = s.values.sort((a, b) => +a.imperial - +b.imperial);
        }
        return s;
    })

    // if an image errors on load, use this placeholder icon
    const imagePlaceholder = "/ResourcePackages/Talon/assets/dist/images/Custom-Icon.png";
    const [imageSrc, setImageSrc] = useState(product.image)

    const onImageError = (error) => {
        if (imageSrc !== imagePlaceholder) {
            setImageSrc(imagePlaceholder);
        }
    }
    
    return (
        <>
            <li className="model-card">
                <a href={product.url} className="model-card__link" aria-label={product.name}></a>
                <div className="model-card__placeholder">
                    {
                        product.image 
                            ? 
                            <img className="model-card__image product-image-fallback" src={imageSrc} alt={product.name} onError={onImageError}></img>
                            : <img className="model-card__image" src={imagePlaceholder} alt="" />
                        
                    }
                </div>
                <div className="model-card__content">
                    <h3 className="model-card__title">{product.name}</h3>
                    {
                        !isComponentType &&
                        <table className="model-card__table">
                            <tbody>
                                {specs.map((s,index) => 
                                    <tr key={index}>
                                        <td>{s.label}</td>
                                        <td>
                                                {/* localestring formats numbers with commas */}
                                                {s.type === 'NUMBER' ? <>
                                                    {
                                                        s.values.length > 1 ?
                                                            <>
                                                                {s.values[0].toLocaleString()} to {s.values.pop().toLocaleString()}
                                                            </>
                                                            : <>{s.values[0].toLocaleString()}</>
                                                    }
                                                </> : s.type === 'MEASUREMENT' ? <>
                                                    {units === "metric" && 
                                                    <>
                                                    {
                                                        s.values[0].metricText && s.values.length > 1
                                                            ? `${s.values[0].metricText} to ${s.values.pop().metricText}`
                                                            : s.values[0].metricText !== "" 
                                                            ? s.values[0].metricText 
                                                            : s.values.length > 1
                                                            ?  `${s.values[0].metric} to ${s.values.pop().metric}`
                                                            : s.values[0].metric
                                                    }
                                                    {/* need to format super tags when using the numeric value */}
                                                    {
                                                        s.units.metric === "m3" && !s.values[0].metricText
                                                            ? <span> m<sup>3</sup></span>
                                                            : !s.values[0].metricText
                                                            ? " " + formatUnitText(s.units.metric)
                                                            : ''
                                                    }
                                                    </>
                                                    }
                                                    {units === "imperial" && 
                                                    <>
                                                    {
                                                        s.values[0].imperialText && s.values.length > 1
                                                            ? `${s.values[0].imperialText} to ${s.values.pop().imperialText}`
                                                            : s.values[0].imperialText !== "" 
                                                            ? s.values[0].imperialText 
                                                            : s.values.length > 1
                                                            ?  `${s.values[0].imperial} to ${s.values.pop().imperial}`
                                                            : s.values[0].imperial
                                                    }
                                                    {
                                                        s.units.imperial === "yd3" && !s.values[0].imperialText
                                                            ? <span> yd<sup>3</sup></span>
                                                            : !s.values[0].imperialText
                                                            ? " " + formatUnitText(s.units.imperial)
                                                            : ''
                                                    }
                                                    </>}
                                                </>
                                                :
                                                <>
                                                    {s.values.join(', ')}
                                                </>
                                                }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                </div>
            </li>
        </>
    )
}

export default ProductCard;