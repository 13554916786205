import React, { useState, useRef, createRef, useEffect } from "react";
import ReactDOM from "react-dom";

const TruckCycleTimeCalculator = (props) => {
	const containerElement = document.getElementById("cycle-time-calculator");
	const [showResult, setShowResult] = useState(false);
	const [inputsValid, setInputsValid] = useState(false);
	const [inputs, updateInputs] = useState([
		{
			id: 0,
			title: "Production Rate of HMA Plant for this project",
			text: "",
			label: "tons/hr",
			placeholder: "0",
			value: "",
			name: "ProductionRate",
			error: false,
		},
		{
			id: 1,
			title: "Truck Capacity",
			text: "",
			label: "tons",
			placeholder: "0",
			value: "",
			name: "TruckCapacity",
			error: false,
		},
		{
			id: 2,
			title: "Truck Loading",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "TruckLoading",
			error: false,
		},
		{
			id: 3,
			title: "Tarp",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "Tarp",
			error: false,
		},
		{
			id: 4,
			title: "Haul To Site",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "HaulToSite",
			error: false,
		},
		{
			id: 5,
			title: "Possible Delay",
			text: "rush hour, school zones, etc.",
			label: "min",
			placeholder: "0",
			value: "",
			name: "PossibleDelay",
			error: false,
		},
		{
			id: 6,
			title: "Waiting to Dump With MTV",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "WaitingToDump",
			error: false,
		},
		{
			id: 7,
			title: "Waiting to Dump Without MTV",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "WaitingToDumpSecondary",
			error: false,
		},
		{
			id: 8,
			title: "Engaged w/ Hopper With MTV",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "EngagedWithHopper",
			error: false,
		},
		{
			id: 9,
			title: "Engaged w/ Hopper Without MTV",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "EngagedWithHopperSecondary",
			error: false,
		},
		{
			id: 10,
			title: "Cleanup",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "Cleanup",
			error: false,
		},
		{
			id: 11,
			title: "Return Haul",
			text: "",
			label: "min",
			placeholder: "0",
			value: "",
			name: "ReturnHaul",
			error: false,
		},
		{
			id: 12,
			title: "Possible Delay",
			text: "rush hour, school zones, etc.",
			label: "min",
			placeholder: "0",
			value: "",
			name: "PossibleDelaySecondary",
			error: false,
		},
	]);
	const [trucksNeeded, setTrucksNeeded] = useState("");
	const [trucksNeededWithDelay, setTrucksNeededWithDelay] = useState("");
	const [trucksNeededSecondary, setTrucksNeededSecondary] = useState("");
	const [trucksNeededWithDelaySecondary, setTrucksNeededWithDelaySecondary] =
		useState("");

	const regexNumbersOnly = /^[0-9\b]+$/;
	const regexNumbersAndDots = /^[0-9]*\.?[0-9]*$/;
	const elementsRef = useRef(inputs.map(() => createRef()));

	const handleInputUpdate = (index) => (e) => {
		const target = e.target;
		const value = Number(target.value);
		if (regexNumbersOnly.test(value) || value === "") {
			updateInputs((inputs) =>
				inputs.map((item) =>
					item.id === index
						? { ...item, value: value, error: false }
						: item
				)
			);
		}
	};

	const getInputValue = (name) => {
		return inputs.filter((x) => x.name === name)[0].value;
	};

	const calculate = () => {
		updateInputs((inputs) =>
			inputs.map((item) =>
				item.value === ""
					? { ...item, error: true }
					: { ...item, error: false }
			)
		);
		let counter = 0;
		inputs.forEach((item) => {
			if (item.value === "") {
				counter++;
				setInputsValid(false);
				if (counter === 1) {
					elementsRef.current[item.id].current.focus();
				}
			}
		});
		if (inputsValid) {
			const productionRate = getInputValue("ProductionRate");
			const truckCapacity = getInputValue("TruckCapacity");
			const truckLoading = getInputValue("TruckLoading");
			const tarp = getInputValue("Tarp");
			const haulToSite = getInputValue("HaulToSite");
			const possibleDelay = getInputValue("PossibleDelay");
			const possibleDelaySecondary = getInputValue(
				"PossibleDelaySecondary"
			);
			const waitingToDump = getInputValue("WaitingToDump");
			const waitingToDumpSecondary = getInputValue(
				"WaitingToDumpSecondary"
			);
			const engagedWithHopper = getInputValue("EngagedWithHopper");
			const engagedWithHopperSecondary = getInputValue(
				"EngagedWithHopperSecondary"
			);
			const cleanup = getInputValue("Cleanup");
			const returnHaul = getInputValue("ReturnHaul");

			const totalNoDelay =
				truckLoading +
				tarp +
				haulToSite +
				waitingToDump +
				engagedWithHopper +
				cleanup +
				returnHaul;
			const totalNoDelaySecondary =
				totalNoDelay -
				waitingToDump -
				engagedWithHopper +
				waitingToDumpSecondary +
				engagedWithHopperSecondary;
			const fullTotal =
				totalNoDelay + possibleDelay + possibleDelaySecondary;
			const fullTotalSecondary =
				totalNoDelaySecondary + possibleDelay + possibleDelaySecondary;
			const trucksNeeded =
				productionRate / (truckCapacity / (totalNoDelay / 60));
			const trucksNeededSecondary =
				productionRate / (truckCapacity / (totalNoDelaySecondary / 60));
			const trucksNeededDelay =
				(productionRate / truckCapacity) * (fullTotal / 60);
			const trucksNeededDelaySecondary =
				(productionRate / truckCapacity) * (fullTotalSecondary / 60);
			setTrucksNeeded(isNaN(trucksNeeded) ? 0 : trucksNeeded.toFixed(1));
			setTrucksNeededSecondary(
				isNaN(trucksNeededSecondary)
					? 0
					: trucksNeededSecondary.toFixed(1)
			);
			setTrucksNeededWithDelay(
				isNaN(trucksNeededDelay) ? 0 : trucksNeededDelay.toFixed(1)
			);
			setTrucksNeededWithDelaySecondary(
				isNaN(trucksNeededDelaySecondary)
					? 0
					: trucksNeededDelaySecondary.toFixed(1)
			);
			setShowResult(true);
		}
	};

	const resetCalculator = () => {
		setShowResult(false);
		setInputsValid(false);
		updateInputs((inputs) =>
			inputs.map((item) => ({
				...item,
				value: "",
				error: false,
			}))
		);
	};

	useEffect(() => {
		setInputsValid(true);
		inputs.forEach((item) => {
			if (item.value === "") {
				setInputsValid(false);
			}
		});
	}, [inputs]);

	return ReactDOM.createPortal(
		<div className="calculator">
			<div className="calculator__header">
				<h2>Shuttle Buggy<sup>&reg;</sup> MTV Trucking</h2>
			</div>
			<div className="calculator__main">
				{inputs.map((item, index) => {
					return (
						<div
							className={`calculator__step ${item.value > 0}`}
							key={index}
						>
							<div className="calculator__text">
								<h3
									id={`cycle-label-${index}`}
									className="calculator__text-heading"
								>
									{item.title}
								</h3>
								<p
									id={`cycle-description-${index}`}
									className="calculator__text-description"
								>
									{item.text}
								</p>
							</div>
							<div className="calculator__input-wrapper">
								<label className="calculator__label">
									<input
										type="text"
										name={item.name}
										ref={elementsRef.current[index]}
										aria-labelledby={`cycle-label-${index}`}
										aria-describedby={`cycle-description-${item.id}`}
										className="calculator__input"
										placeholder={item.placeholder}
										onChange={handleInputUpdate(index)}
										value={item.value}
										disabled={showResult ? 1 : 0}
										required
									/>
									<span className="calculator__input-label">
										{item.label}
									</span>
									{item.error && (
										<span className="calculator__error">
											{item.title} is required.
										</span>
									)}
								</label>
							</div>
						</div>
					);
				})}
			</div>
			<div
				className={`calculator__footer ${showResult}`}
				aria-live="polite"
			>
				{!showResult ? (
					<>
					<div className={`calculator__footer-content`}>
					</div>
					<div className="calculator__results">
						<button
							type="button"
							className="calculator__footer-button button button--white"
							onClick={calculate}
						>
							Calculate
						</button>
					</div>
					</>
				) : (
					<>
					<div className="calculator__footer-row">
						<div className={`calculator__footer-content`}>
							<h3 className="calculator__footer-heading calculator__footer-heading--secondary">
								Trucks Needed With MTV
							</h3>
						</div>

						<div className="calculator__results">
							<table className="results-table">
								<tbody>
									<tr className="results-table__row">
										<td className="results-table__cell results-table__cell--secondary">
											Trucks Needed
										</td>
										<td className="results-table__cell results-table__cell--secondary">
											{trucksNeeded}
										</td>
									</tr>
									<tr className="results-table__row">
										<td className="results-table__cell results-table__cell--secondary">
											Trucks Needed With Delay
										</td>
										<td className="results-table__cell results-table__cell--secondary">
											{trucksNeededWithDelay}
										</td>
									</tr>
								</tbody>
							</table>

						</div>
					</div>

					<div className="calculator__footer-row">
						<div className={`calculator__footer-content`}>
							<h3 className="calculator__footer-heading calculator__footer-heading--secondary">
								Trucks Needed Without MTV
							</h3>
						</div>

						<div className="calculator__results">
							<table className="results-table">
								<tbody>
									<tr className="results-table__row">
										<td className="results-table__cell results-table__cell--secondary">
											Trucks Needed
										</td>
										<td className="results-table__cell results-table__cell--secondary">
											{trucksNeededSecondary}
										</td>
									</tr>
									<tr className="results-table__row">
										<td className="results-table__cell results-table__cell--secondary">
											Trucks Needed With Delay
										</td>
										<td className="results-table__cell results-table__cell--secondary">
											{trucksNeededWithDelaySecondary}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
						
					<button
						type="button"
						className="calculator__reset calculator__reset--secondary"
						onClick={resetCalculator}
					>
						<svg
							className="calculator__icon"
							aria-hidden="true"
							focusable="false"
							viewBox="0 0 13 16"
						>
							<use xlinkHref="#icon-reset"></use>
						</svg>
						Reset Calculator
					</button>
					</>
				)}
				{
					showResult && <div><br></br><p><em>
					Due to changing operating and material conditions prevalent in any material processing, the shown figures should be considered as theoretical estimates only and are based on assumed input values provided by the customer. Astec, and its affiliate brands, make no guarantee of actual field results, and therefore, no production performance or warranty applies</em></p></div>
				}
			</div>
		</div>,
		containerElement
	);
};
export default TruckCycleTimeCalculator;
