import React, { useState, useRef, useEffect } from "react";
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	InfoWindow,
	MarkerClusterer
} from "@react-google-maps/api";

import clusterIcon from "../../images/icon-cluster-pin.png";

const FacilityMap = (props) => {
	const [map, setMap] = useState(null);
	const [activeMarkerId, setActiveMarkerId] = useState(null);
	const mapZoom = 4;
	const mapStyles = {
		height: "100%",
		width: "100%",
	};
	const markerImage = require("../../images/icon-pin.png");

	const defaultBounds = {
		north: 45,
		south: -25,
		west: -120,
		east: 135,
	}

	const clusterStyles = [
		{
			url: clusterIcon,
			height: 53,
			width: 53,
			textColor: "white",
		}
	];
	// Extend the map bounds for every location
	const setMapBounds = (map) => {
		map.fitBounds(defaultBounds);
		setMap(map);
	};

	// lift marker state id to listing component so we can scroll to the result
	const handleMarkerClick = (id) => {
		setActiveMarkerId(id);
		props.setActiveMarker(id);
	}

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyC1E9m76HVzmwOGsgMqEns4uBV9UOzzugY",
	});

	const onLoad = React.useCallback(function callback(map) {
		setMapBounds(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	const generateAddress = (street,city,state,country,zip) => {
		let addressLine2 = "";
		if(street) {
			addressLine2 += street;
		}
		if(city) {
			addressLine2 += `, ${city}`;
		}
		if(state) {
			addressLine2 += `, ${state}`;
		}
		if (country) {
			addressLine2 += `, ${country}`;
		}
		if(zip) {
			addressLine2 += ` ${zip}`;
		}
		return addressLine2;
	};


	// When clicking on view details in the results listing
	// update the active marker
	useEffect(() => {
		setActiveMarkerId(props.activeMarker);
	},[props.activeMarker]);

	useEffect(() => {
		// Set map bounds again when users click search multiple times
	}, [props]);

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={mapStyles}
			// center={mapCenter}
			zoom={mapZoom}
			onLoad={onLoad}
			onUnmount={onUnmount}
		>
			<MarkerClusterer styles={clusterStyles} imagePath="/">
			{(clusterer) =>
				props.locations.map((item) => {
					const latLng = {
						lat: item.Address?.Latitude || 0,
						lng: item.Address?.Longitude || 0,
					};
					const markerId = item.Id;
					// 24 36 pin size default
					// 38 56 active pin size
					// 72 90 original image size
					const markerIcon = {
						url: markerImage,
						scaledSize: markerId === activeMarkerId
							? new window.google.maps.Size(45,55)
							: new window.google.maps.Size(30,38),
						origin: new window.google.maps.Point(0, 0),
						anchor: markerId === activeMarkerId
							? new window.google.maps.Point(22, 55)
							: new window.google.maps.Point(15, 38),
					};
					if(!item.Address?.Latitude && !item.Address?.Longitude) {
						return false
					}
					return (
						<Marker
							key={markerId}
							icon={markerIcon}
							position={latLng}
							clusterer={clusterer}
							onClick={() => handleMarkerClick(markerId)}
						>
							{markerId === activeMarkerId && (
								<InfoWindow
									onCloseClick={() => {handleMarkerClick(null)}}
								>
									<div className="facility-item facility-item--secondary">
										{item.RelatedImage.length > 0 ? (
											<img
												className="facility-item__image"
												src={item.RelatedImage[0].Url}
												alt={
													item.RelatedImage[0]
														.AlternativeText
												}
											/>
										) : (
											""
										)}
										<div className="facility-item__content">
											<div className="facility-item__info">
												<svg
													className="facility-item__icon"
													aria-hidden="true"
													focusable="false"
													viewBox="0 0 21.4 32"
												>
													<use xlinkHref="#icon-pin"></use>
												</svg>
												<span className="facility-item__address">
													{generateAddress(item.Address?.Street, item.Address?.City, item.Address?.StateCode, item.Address?.CountryCode, item.Address?.Zip)}
												</span>
												<a className="facility-item__link"
													href={`https://www.google.com/maps/search/?api=1&query=${item.Address?.Latitude},${item.Address?.Longitude}`}
													target="_blank"
												>
													Get Directions
												</a>
											</div>
											<div className="facility-item__info">
												{
													(item.OfficePhone !== "" || item.PartsPhone !== "" || item.ServicePhone !== "") &&
													<svg
														className="facility-item__icon"
														aria-hidden="true"
														focusable="false"
														viewBox="0 0 32 32"
													>
														<use xlinkHref="#icon-phone"></use>
													</svg>
												}
												{
													item.OfficePhone !== "" &&
													<p>
														<span className="facility-item__label">Office:</span>{" "}
														<a className="facility-item__link facility-item__link--secondary" href={`tel:${item.OfficePhone}`}>{item.OfficePhone}</a>
													</p>
												}
												{
													item.PartsPhone !== "" &&
													<p>
														<span className="facility-item__label">Parts:</span>{" "}
														<a className="facility-item__link facility-item__link--secondary" href={`tel:${item.PartsPhone}`}>{item.PartsPhone}</a>
													</p>
												}
												{
													item.ServicePhone !== "" &&
													<p>
														<span className="facility-item__label">Service:</span>{" "}
														<a className="facility-item__link facility-item__link--secondary" href={`tel:${item.ServicePhone}`}>{item.ServicePhone}</a>
													</p>
												}
											</div>
										</div>
									</div>
								</InfoWindow>
							)}
						</Marker>
					);
				})
			}
			</MarkerClusterer>
		</GoogleMap>
	) : (
		<></>
	);
};
export default FacilityMap;
