import React, { useEffect, useState } from "react";

import ProductCard from "../products/card";
import ProductFacets from "../products/facets";
import ReactDOM from "react-dom";
import queryString from "query-string";
import SkeletonCards from "../products/skeletonCards";
import useSWR from "swr";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const poster = (url, data) => {
	const options = {
		method: "POST",
		...(data && { body: JSON.stringify(data) }),
		headers: {
			accept: "application/json",
			"Content-Type": "application/json",
		},
	};
	return fetch(url, options).then((res) => res.json());
};

const ElasticProducts = () => {
	const containerElement = document.getElementById("elastic-products");
	const localStorageUnit = localStorage.getItem("unitSystem");
	const [units, setUnits] = useState(
		localStorageUnit ? localStorageUnit : "imperial"
	);
	const [showUnits, updateShowUnits] = useState(false);
	const indexName = queryString.parse(window?.location?.search)?.indexName;
	const config = JSON.parse(
		JSON.stringify(window.astec?.config?.paramsConfig)
	);
	config.specFacets = config.specFacets.filter(
		(f) => !f.suffix.includes(`.${units === "imperial" ? "metric" : "imperial"}`)
	);
	const [params, setParams] = useState(config);
	const [facets, setFacets] = useState();
	const [selectedFacets, setSelectedFacets] = useState([]);
	const { data, error } = useSWR(
		[
			params &&
				`/api/elastic/search${
					indexName ? `?indexName=${indexName}` : ""
				}`,
			params,
		],
		poster,
		{ revalidateOnFocus: false, shouldRetryOnError: false }
	);
	const totalPageCount = data ? Math.ceil(data.total / params.perPage) : null;
	// create array of page numbers to display, starting with 1
	const totalPages = data
		? Array.from({ length: totalPageCount }, (_, i) => i + 1)
		: [];

	if (!facets && data) {
		setFacets(
			data.facets.map((f) => {
				if (params.specFacets.find((fa) => fa.field === f.field)) {
					f.isSpecFacet = true;
				}
				return f;
			})
		);

		// show unit toggle only if a spec type has unit values
		data.results.map((result) => {
			result.models.map((model) => {
				model.specs.map((spec) => {
					if(spec.type === "MEASUREMENT" || spec.type === "METRICIMPERIALFREETEXT") {
						if (!showUnits) {
							updateShowUnits(true);
						}
					}
				});
			})
		});
	}

	const goToPage = (page) => {
		const newParams = { ...params };
		newParams.page = page;
		setParams(newParams);
	};

	const toggleUnits = (unit) => {
		const newParams = JSON.parse(
			JSON.stringify(window.astec?.config?.paramsConfig)
		);
		if (
			newParams.specFacets.find(
				(f) => f.suffix.includes(".metric") || f.suffix.includes(".imperial")
			)
		) {
			// alert user that params will reset
			newParams.specFacets = newParams.specFacets.filter(
				(f) =>
					!f.suffix.includes(`.${unit === "imperial" ? "metric" : "imperial"}`)
			);
			setParams(newParams);
		}
		localStorage.setItem("unitSystem", unit);
		setUnits(unit);
		setFacets();
	};

	const [productData, setProductData] = useState([]);
	const [defaultFetchStatus, setDefaultFetchStatus] = useState(false);
	let defaultData = [];
    const productsAPI =
    "/sfapi/default/products1?$select=Title,Summary,UrlName&$expand=Image,ProductPages&$orderby=Title asc";

	// if the elastic api fails, request all products and show products with related product page equal to the current page
	const getDefaultResults = (api) => {
		fetch(api)
			.then((res) => res.json())
			.then((data) => {
				defaultData = [...defaultData, ...data.value];
				if (data["@odata.nextLink"]) {
					getDefaultResults(data["@odata.nextLink"]);
				} else {
					const pageId = config.productPage;
					const results = defaultData.filter((item) =>
						item.ProductPages.some((page) => page.Id === pageId)
					);
					setProductData(results);
				}
			})
			.catch((error) => {
				console.error("Error retrieving product results", error);
			});
	};

	if (error || (data && data.results.length === 0)) {
		if (!defaultFetchStatus) {
			// while waiting for results, show the skeleton loaders
			// after 1 second, show the default results
			setTimeout(() => {
				setDefaultFetchStatus(true);
				getDefaultResults(productsAPI);
			},1000)
		}
	}

	const createSummary = (description) => {
		return {
			__html: description,
		};
	};

	if (data && data.total > 0) {
		return ReactDOM.createPortal(
			<div className="product-cards">
				{showUnits && (
					<div className="spec-tables__toggle spec-tables__toggle--mobile">
						<div className="spec-tables__label">
							<div className="spec-tables__units">UNITS:</div>
							<button
								type="button"
								onClick={() => toggleUnits("metric")}
								className={units === "metric" ? "active" : ""}
								data-units="metric"
							>
								Metric (mm)
							</button>
							<button
								type="button"
								className={units === "imperial" ? "active" : ""}
								onClick={() => toggleUnits("imperial")}
								data-units="imperial"
							>
								Imperial (in)
							</button>
						</div>
					</div>
				)}
				{
					<div className="product-cards__results product-cards__results--mobile">
						{data?.total} results found
					</div>
				}
				<ProductFacets
					units={units}
					setParams={setParams}
					params={params}
					facets={facets}
					liveFacets={data?.facets}
					setSelected={setSelectedFacets}
					selected={selectedFacets}
				></ProductFacets>

				<div className="product-cards__results">
					<>{data.total} results found</>
				</div>

				{showUnits && (
					<div className="spec-tables__toggle spec-tables__toggle--desktop">
						<div className="spec-tables__label">
							<div className="spec-tables__units">UNITS:</div>
							<button
								type="button"
								onClick={() => toggleUnits("metric")}
								className={units === "metric" ? "active" : ""}
								data-units="metric"
							>
								Metric (mm)
							</button>
							<button
								type="button"
								className={units === "imperial" ? "active" : ""}
								onClick={() => toggleUnits("imperial")}
								data-units="imperial"
							>
								Imperial (in)
							</button>
						</div>
					</div>
				)}

				<ul className="product-cards__list">
					{data?.results.map((r, index) => (
						<ProductCard
							key={index}
							units={units}
							facets={facets}
							product={r}
						></ProductCard>
					))}
				</ul>
				{totalPageCount > 1 ? (
					<div className="product-pagination">
						<button
							type="button"
							className="product-pagination__button"
							onClick={() => goToPage(params.page - 1)}
							disabled={params.page <= 1}
						>
							<span className="visually-hidden">
								Previous Page
							</span>
						</button>
						<ul className="product-pagination__list">
							{totalPages.map((page) => {
								return (
									<li
										className="product-pagination__item"
										key={page}
									>
										<button
											type="button"
											className={`product-pagination__link ${
												params.page === page
													? "active"
													: ""
											}`}
											onClick={() => goToPage(page)}
										>
											{page}
										</button>
									</li>
								);
							})}
						</ul>
						<button
							type="button"
							className="product-pagination__button product-pagination__button--secondary"
							onClick={() => goToPage(params.page + 1)}
							disabled={params.page >= totalPageCount}
						>
							<span className="visually-hidden">Next Page</span>
						</button>
					</div>
				) : (
					""
				)}
			</div>,
			containerElement
		);
	} else {
		if (!defaultFetchStatus) {
			return ReactDOM.createPortal(
				<div className="product-cards">            
				{
					showUnits &&
					<div className="spec-tables__toggle spec-tables__toggle--mobile">
						<div className="spec-tables__label">
							<div className="spec-tables__units">UNITS:</div> 
							<button type="button" onClick={() => toggleUnits('metric')} className={units === "metric" ? "active" : ""} data-units="metric">Metric (mm)</button>
							<button type="button" className={units === "imperial" ? "active" : ""} onClick={() => toggleUnits('imperial')} data-units="imperial">Imperial (in)</button>
						</div>
					</div>
				}

				<div className="product-cards__results product-cards__results--mobile">
					<Skeleton width={100} height={30}></Skeleton>
				</div>

				<ProductFacets units={units} setParams={setParams} params={params} facets={facets} liveFacets={data?.facets} setSelected={setSelectedFacets} selected={selectedFacets}></ProductFacets>

				<div className="product-cards__results">
					<>{data?.total} results found</>
				</div>

				{showUnits && (
					<div className="spec-tables__toggle spec-tables__toggle--desktop">
						<div className="spec-tables__label">
							<div className="spec-tables__units">UNITS:</div>
							<button
								type="button"
								onClick={() => toggleUnits("metric")}
								className={units === "metric" ? "active" : ""}
								data-units="metric"
							>
								Metric (mm)
							</button>
							<button
								type="button"
								className={units === "imperial" ? "active" : ""}
								onClick={() => toggleUnits("imperial")}
								data-units="imperial"
							>
								Imperial (in)
							</button>
						</div>
					</div>
				)}

				<SkeletonCards></SkeletonCards>


				</div>,
				containerElement
			)
		} else {
			return ReactDOM.createPortal(
				<div className="related-products-grid__container">
					{productData.map((item, index) => {
						return (
							<div
								className="related-products-grid__item"
								key={index}
							>
								<a
									href={`/products/detail/${item.UrlName}`}
									className="related-products__link"
									aria-label={`view more information about ${item.Title}`}
								></a>
								<div className="related-products__image-container">
									{item.Image.length > 0 ? 
										<img
											src={item.Image[0].Url}
											className="related-products__image"
											alt={item.Image[0].AlternativeText}
										/>
										: <img src="/ResourcePackages/Talon/assets/dist/images/Custom-Icon.png" alt="" className="related-products__image" />
									}
								</div>
								<div className="related-products__content">
									<h3 className="related-products__title">
										{item.Title}
									</h3>
									<div
										className="related-products__description"
										dangerouslySetInnerHTML={createSummary(
											item.Summary
										)}
									/>
								</div>
							</div>
						);
					})}
				</div>,
				containerElement
			);
		}
	}
};

export default ElasticProducts;
