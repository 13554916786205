import React from "react";

const Svgs = () => {
	return (
		<svg className="svg-legend">
			<defs>
				<symbol id="icon-phone" viewBox="0 0 32 32">
					<path d="M22 20c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.109 12.109 8 16s12 8 16 8c0 0 6-4 6-6s-6-8-8-6z"></path>
				</symbol>
				<symbol id="icon-filter" viewBox="0 0 16 16">
					<g
						id="Filter-Options-+-Specifications-Within-Cards"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							transform="translate(-446.000000, -2820.000000)"
							fill="#444B4F"
							fillRule="nonzero"
							id="Filter"
						>
							<g transform="translate(430.000000, 2808.000000)">
								<g
									transform="translate(16.000000, 8.000000)"
									id="icon-filter"
								>
									<path d="M4,15.2 C5.18915995,15.2 6.17632136,16.0648586 6.36679809,17.1998896 L15.2,17.2 C15.6418278,17.2 16,17.5581722 16,18 C16,18.4102687 15.6911678,18.7484057 15.2932969,18.7946178 L15.2,18.8 L6.07918877,18.79954 C5.66428298,19.5171553 4.8885287,20 4,20 C3.1114713,20 2.33571702,19.5171553 1.92081123,18.79954 L0.8,18.8 C0.3581722,18.8 0,18.4418278 0,18 C0,17.5897313 0.308832152,17.2515943 0.7067031,17.2053822 L0.8,17.2 L1.63320191,17.1998896 C1.82367864,16.0648586 2.81084005,15.2 4,15.2 Z M4,16.8 C3.5581722,16.8 3.2,17.1581722 3.2,17.6 C3.2,18.0418278 3.5581722,18.4 4,18.4 C4.4418278,18.4 4.8,18.0418278 4.8,17.6 C4.8,17.1581722 4.4418278,16.8 4,16.8 Z M12,9.6 C13.0448017,9.6 13.9336713,10.2676245 14.2632714,11.1995361 L15.2,11.2 C15.6418278,11.2 16,11.5581722 16,12 C16,12.4102687 15.6911678,12.7484057 15.2932969,12.7946178 L15.2,12.8 L14.2632714,12.8004639 C13.9336713,13.7323755 13.0448017,14.4 12,14.4 C10.9551983,14.4 10.0663287,13.7323755 9.73672859,12.8004639 L0.8,12.8 C0.3581722,12.8 0,12.4418278 0,12 C0,11.5897313 0.308832152,11.2515943 0.7067031,11.2053822 L0.8,11.2 L9.73672859,11.1995361 C10.0663287,10.2676245 10.9551983,9.6 12,9.6 Z M12,11.2 C11.5581722,11.2 11.2,11.5581722 11.2,12 C11.2,12.4418278 11.5581722,12.8 12,12.8 C12.4418278,12.8 12.8,12.4418278 12.8,12 C12.8,11.5581722 12.4418278,11.2 12,11.2 Z M4,4 C5.04517998,4 5.93431495,4.66810805 6.26362919,5.6005484 L15.2,5.6 C15.6418278,5.6 16,5.9581722 16,6.4 C16,6.81026867 15.6911678,7.14840573 15.2932969,7.19461781 L15.2,7.2 L6.26327141,7.20046392 C5.93367133,8.13237547 5.0448017,8.8 4,8.8 C2.9551983,8.8 2.06632867,8.13237547 1.73672859,7.20046392 L0.8,7.2 C0.3581722,7.2 0,6.8418278 0,6.4 C0,5.98973133 0.308832152,5.65159427 0.7067031,5.60538219 L0.8,5.6 L1.73637081,5.6005484 C2.06568505,4.66810805 2.95482002,4 4,4 Z M4,5.6 C3.5581722,5.6 3.2,5.9581722 3.2,6.4 C3.2,6.8418278 3.5581722,7.2 4,7.2 C4.4418278,7.2 4.8,6.8418278 4.8,6.4 C4.8,5.9581722 4.4418278,5.6 4,5.6 Z"></path>
								</g>
							</g>
						</g>
					</g>
				</symbol>
				<symbol id="icon-reset" viewBox="0 0 13 16">
					<g
						id="Production-Rate---Calculator"
						stroke="none"
						strokeWidth="1"
					>
						<g
							id="Production-Rate---State-2"
							transform="translate(-1127.000000, -592.000000)"
						>
							<g
								id="Calculator"
								transform="translate(440.000000, 152.000000)"
							>
								<g
									id="Result---state-2"
									transform="translate(0.000000, 336.000000)"
								>
									<g
										id="CTA"
										transform="translate(687.008470, 100.000000)"
									>
										<path
											d="M6.40780191,7.22276624 C8.18081896,7.22276624 9.69323927,7.84787865 10.9418653,9.09650473 C12.1920902,10.3451308 12.8156038,11.8447611 12.8156038,13.5921981 C12.8156038,15.3668139 12.1856952,16.8776354 10.9226803,18.1262615 C9.66126421,19.3764863 8.15683767,20 6.40780191,20 C4.65876616,20 3.15433962,19.3764863 1.89292352,18.1262615 C0.631507424,16.8776354 0,15.3668139 0,13.5921981 L1.611543,13.5921981 C1.611543,14.9159655 2.07997762,16.0478827 3.01684686,16.9831531 C3.9537161,17.9200224 5.08403445,18.388457 6.40780191,18.388457 C7.73156938,18.388457 8.86188773,17.9200224 9.79875697,16.9831531 C10.7356262,16.0478827 11.2040608,14.9159655 11.2040608,13.5921981 C11.2040608,12.2684306 10.7356262,11.1381123 9.79875697,10.201243 C8.86188773,9.26437379 7.73156938,8.79593917 6.40780191,8.79593917 L6.40780191,12.0190252 L2.39812946,8.0093527 L6.40780191,3.99968025 L6.40780191,7.22276624 Z"
											id="icon-reset"
										></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</symbol>
				<symbol id="icon-pin" viewBox="0 0 21.4 32">
					<path
						id="path-1_1_"
						d="M10.7,0C4.8,0,0,4.8,0,10.7c0,1.8,0.4,3.5,1.3,5.1l8.8,15.9c0.1,0.2,0.3,0.3,0.6,0.3s0.5-0.1,0.6-0.3
	l8.8-15.9c0.8-1.5,1.3-3.3,1.3-5.1C21.4,4.8,16.6,0,10.7,0z M10.7,16c-2.9,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3S16,7.8,16,10.7
	S13.6,16,10.7,16z"
					/>
				</symbol>
				<symbol id="icon-location" viewBox="0 0 16 28">
					<path d="M12 10c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM16 10c0 0.953-0.109 1.937-0.516 2.797l-5.688 12.094c-0.328 0.688-1.047 1.109-1.797 1.109s-1.469-0.422-1.781-1.109l-5.703-12.094c-0.406-0.859-0.516-1.844-0.516-2.797 0-4.422 3.578-8 8-8s8 3.578 8 8z"></path>
				</symbol>
				<symbol id="icon-map2" viewBox="0 0 32 32">
					<path d="M21 6l-10-4-11 4v24l11-4 10 4 11-4v-24l-11 4zM12 4.554l8 3.2v19.692l-8-3.2v-19.692zM2 7.401l8-2.909v19.744l-8 2.909v-19.744zM30 24.599l-8 2.909v-19.744l8-2.909v19.744z"></path>
				</symbol>
				<symbol id="icon-info" viewBox="0 0 24 24">
					<defs>
						<path
							d="M12,0 C18.6324375,0 24,5.36704687 24,12 C24,18.6324844 18.6329531,24 12,24 C5.36751563,24 0,18.6329531 0,12 C0,5.36751563 5.36704687,0 12,0 Z M12,1.875 C6.40382813,1.875 1.875,6.40345313 1.875,12 C1.875,17.5961719 6.40345313,22.125 12,22.125 C17.596125,22.125 22.125,17.5965469 22.125,12 C22.125,6.40382813 17.5965469,1.875 12,1.875 Z M12,10.0467187 C12.5177812,10.0467187 12.9375,10.4664375 12.9375,10.9842187 L12.9375,10.9842187 L12.9375,17.0213437 C12.9375,17.539125 12.5177812,17.9588906 12,17.9588906 C11.4822188,17.9588906 11.0625,17.5391719 11.0625,17.0213906 L11.0625,17.0213906 L11.0625,10.9842187 C11.0625,10.4664375 11.4822188,10.0467187 12,10.0467187 Z M12,6.3675 C12.6989854,6.3675 13.265625,6.93413961 13.265625,7.633125 C13.265625,8.33211039 12.6989854,8.89875 12,8.89875 C11.3010146,8.89875 10.734375,8.33211039 10.734375,7.633125 C10.734375,6.93413961 11.3010146,6.3675 12,6.3675 Z"
							id="path-1"
						></path>
						<rect
							id="path-3"
							x="0"
							y="0"
							width="28"
							height="28"
						></rect>
					</defs>
					<g
						id="Icons"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							id="A0"
							transform="translate(-91.000000, -553.000000)"
						>
							<g
								id="Group"
								transform="translate(91.000000, 553.000000)"
							>
								<mask id="mask-2" fill="white">
									<use xlinkHref="#path-1"></use>
								</mask>
								<use
									id="Combined-Shape"
									fill="#000000"
									fillRule="nonzero"
									xlinkHref="#path-1"
								></use>
								<g mask="url(#mask-2)" id="🎨-color">
									<g
										transform="translate(-2.000000, -2.000000)"
										id="Rectangle"
									>
										<use
											fill="#BCBEC0"
											xlinkHref="#path-3"
										></use>
										<use
											fill="#D8D8D8"
											xlinkHref="#path-3"
										></use>
										<use
											fill="#0058A5"
											xlinkHref="#path-3"
										></use>
									</g>
								</g>
							</g>
						</g>
					</g>
				</symbol>
				<symbol id="icon-search" viewBox="0 0 20 20">
					<path d="M12.9 14.32a7.947 7.947 0 01-4.908 1.682 8 8 0 116.305-3.075l.013-.018 5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 108 2a6 6 0 100 12z" />
				</symbol>
				<symbol id="icon-logo-only" viewBox="0 0 52.4 41.7">
					<g
						id="Components_x2F_global_x2F_header_x2F_desktop-3"
						transform="translate(-33.000000, -21.000000)"
					>
						<g
							id="logo_x2F_horizontal_x2F_color_x2F_light-bg"
							transform="translate(33.000000, 21.000000)"
						>
							<path
								id="Fill-5"
								className="st0"
								fill="#BBBDBF"
								d="M21.2,0h-4.6c-0.8,0-1.5,0.5-1.8,1.3L0.1,39c-0.5,1.3,0.5,2.7,1.8,2.7h10c0.8,0,1.5-0.5,1.8-1.3
				l11.5-29.6L21.2,0z"
							/>
							<path
								id="Fill-7"
								className="st1"
								fill="#0056A5"
								d="M50.5,41.7h-10c-0.8,0-1.5-0.5-1.8-1.3l-8.1-21L26.2,8.5L22.9,0h12.9c0.8,0,1.6,0.5,1.9,1.3
				l7,18.2L52.3,39C52.8,40.3,51.8,41.7,50.5,41.7"
							/>
							<path
								id="Fill-9"
								className="st2"
								fill="#404042"
								d="M30.3,30.5c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5s2-4.5,4.5-4.5S30.3,28,30.3,30.5"
							/>
						</g>
					</g>
				</symbol>
			</defs>
		</svg>
	);
};
export default Svgs;
