import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonCards = () => {

    return (
		<ul className="product-cards__list">
			{
				Array(4).fill().map(() => (
					<li className="model-card">
						<Skeleton height={200} baseColor={`#cacaca`}></Skeleton>
						<div className="model-card__content">
							<h3 className="model-card__title">
								<Skeleton height={50}></Skeleton>
							</h3>
							<table className="model-card__table">
								<Skeleton height={20} count={5}></Skeleton>
							</table>
						</div>
					</li>
				))
			}
		</ul>
    )
}

export default SkeletonCards;