import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";

const LocationMap = (props) => {
  const [map, setMap] = useState(null);
  const [markerName, setMarkerName] = useState(null);
  const mapZoom = 4;
  const locationRef = useRef();
  const dealerRef = useRef();

  dealerRef.current = props.dealer;
  locationRef.current = props.locations;
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC1E9m76HVzmwOGsgMqEns4uBV9UOzzugY",
  });

  const setMapBounds = (map) => {
    // Extend the map bounds for every location
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < locationRef.current.length; i++) {
      const locationItem = locationRef.current[i];
      if(locationItem.Address) {
        const latLng = {
          lat: locationItem.Address.Latitude || 0,
          lng: locationItem.Address.Longitude || 0,
        };
        bounds.extend(latLng);
      }
    }
    if(dealerRef.current.Address) {
      const dealerCoords = {
        lat: dealerRef.current.Address.Latitude || 0,
        lng: dealerRef.current.Address.Longitude || 0,
      };
      bounds.extend(dealerCoords);
    }
    map.fitBounds(bounds);
    setMap(map);
  };

  const onLoad = React.useCallback(function callback(map) {
    setMapBounds(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);


  useEffect(() => {
    // Set map bounds again when users click search multiple times
    if(map) {
      setMapBounds(map);
    }
  },[props]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyles}
      // center={mapCenter}
      zoom={mapZoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {props.locations.map((item,index) => {
      const locationMarker = {
        path:
          "M16,0C10.1,0,5.3,4.8,5.3,10.7c0,1.8,0.4,3.5,1.3,5.1l8.8,15.9c0.1,0.2,0.3,0.3,0.6,0.3 s0.5-0.1,0.6-0.3l8.8-15.9c0.8-1.5,1.3-3.3,1.3-5.1C26.7,4.8,21.9,0,16,0z M16,16c-2.9,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3 s5.3,2.4,5.3,5.3S18.9,16,16,16z",
        fillColor: "#0058A5",
        fillOpacity: 1,
        strokeColor: "#000",
        strokeWeight: 1,
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(11, 32),
      };    
        if(item.Address) {
          const latLng = {
            lat: item.Address.Latitude || 0,
            lng: item.Address.Longitude || 0,
          };
          const markerId = item.Title + index;
          return (
            <Marker
              key={markerId}
              icon={locationMarker}
              position={latLng}
              onClick={() => {
                setMarkerName(markerId)
              }}
            >
            {
              markerId === markerName &&
              <InfoWindow onCloseClick={() => {setMarkerName(null)}}>
                <div className="info-window">
                  <p className="info-window__detail">{item.Title}</p>
                  <p className="info-window__detail">                  
                    {item.Address?.Street && item.Address.Street + ' '}
                    {item.Address?.City && item.Address.City + ', '}
                    {item.Address?.StateCode && item.Address.StateCode + ' '}
                    {item.Address?.Zip && item.Address.Zip}</p>
                  <p className="info-window__link"><a href={`tel:${item.Phone}`}>{item.Phone}</a></p>
                  <p className="info-window__link"><a href={`https://www.google.com/maps/search/?api=1&query=${item.Address?.Latitude},${item.Address?.Longitude}`} target="_blank">Get Directions</a></p>
                </div>
              </InfoWindow>
            }
            </Marker>
          );
        }
      })}
      {props.dealer && props.dealer.Address && (
        <Marker
          key={props.dealer.Name}
          onClick={() => {
            setMarkerName(props.dealer.Name)
          }}
          icon={{
            path:
              "M16,0C10.1,0,5.3,4.8,5.3,10.7c0,1.8,0.4,3.5,1.3,5.1l8.8,15.9c0.1,0.2,0.3,0.3,0.6,0.3 s0.5-0.1,0.6-0.3l8.8-15.9c0.8-1.5,1.3-3.3,1.3-5.1C26.7,4.8,21.9,0,16,0z M16,16c-2.9,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3 s5.3,2.4,5.3,5.3S18.9,16,16,16z",
            fillColor: "#0058A5",
            fillOpacity: 1,
            strokeColor: "#000",
            strokeWeight: 1,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(11, 32),
          }}
          position={{lat: props.dealer.Address.Latitude || 0, lng: props.dealer.Address.Longitude || 0}}
        >
          {
            markerName === props.dealer.Name &&
            <InfoWindow onCloseClick={() => {setMarkerName(null)}}>
              <div className="info-window">
                <p className="info-window__detail">{props.dealer.Name}</p>
                <p className="info-window__detail">                  
                  {props.dealer.Address?.Street && props.dealer.Address.Street + ' '}
                  {props.dealer.Address?.City && props.dealer.Address.City + ', '}
                  {props.dealer.Address?.StateCode && props.dealer.Address.StateCode + ' '}
                  {props.dealer.Address?.Zip && props.dealer.Address.Zip}</p>
                <p className="info-window__detail">{props.dealer.Email}</p>
                <p className="info-window__link"><a href={`tel:${props.dealer.Phone}`}>{props.dealer.Phone}</a></p>
                <p className="info-window__link"><a href={`https://www.google.com/maps/search/?api=1&query=${props.dealer.Address?.Latitude},${props.dealer.Address?.Longitude}`} target="_blank">Get Directions</a></p>
              </div>
            </InfoWindow>
          }
        </Marker>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};
export default LocationMap;
