export function formatUnitText (unit) {
	switch (unit) {
		case "l/min":
			return " LPM"
		case "us-gal/min":
			return " USGPM"
		case "t/h":
			return " TPH";
		case "mt/h":
			return " MTPH";
		default:
			return " " + unit;
	}
};