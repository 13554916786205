import React, { useState, useRef, createRef, useEffect } from "react";
import ReactDOM from "react-dom";

const PavingCalculator = (props) => {
	const containerElement = document.getElementById("paving-calculator");
	const [showResult, setShowResult] = useState(false);
	const [inputsValid, setInputsValid] = useState(false);
	const [inputs, updateInputs] = useState([
		{
			id: 0,
			title: "Total Paving Time",
			text: "",
			label: "hours",
			placeholder: "0",
			value: "",
			name: "TotalPavingTime",
			error: false,
			required: true,
		},
		{
			id: 1,
			title: "Production Rate of HMA Plant",
			text: "",
			label: "tons/hr",
			placeholder: "0",
			value: "",
			name: "ProductionRate",
			error: false,
			required: true,
		},
		{
			id: 2,
			title: "Truck Capacity",
			text: "",
			label: "tons",
			placeholder: "0",
			value: "",
			name: "TruckCapacity",
			error: false,
			required: true,
		},
		{
			id: 3,
			title: "Paving Thickness",
			text: "",
			label: "inches",
			placeholder: "0",
			value: "",
			name: "PavingThickness",
			error: false,
			required: true,
		},
		{
			id: 4,
			title: "Paving Width",
			text: "",
			label: "feet",
			placeholder: "0",
			value: "",
			name: "PavingWidth",
			error: false,
			required: true,
		},
		{
			id: 5,
			title: "Uncompacted Material Density",
			text: "",
			label: "lbs/ft",
			super: "3",
			placeholder: "0",
			value: "",
			name: "MaterialDensity",
			error: false,
			required: true,
		},
		{
			id: 6,
			title: "Density of Mat Before Rolling",
			text: "",
			label: "percent",
			placeholder: "0",
			value: "",
			name: "DensityOfMat",
			error: false,
			required: true,
		},
		{
			id: 7,
			title: "Final Density Required",
			text: "",
			label: "percent",
			placeholder: "0",
			value: "",
			name: "FinalDensity",
			error: false,
			required: true,
		},
		{
			id: 8,
			title: "Actual Length",
			text: "",
			label: "feet",
			placeholder: "0",
			value: "",
			name: "ActualLength",
			error: false,
			required: false,
		}
	]);
	const [pavingSpeed, setPavingSpeed] = useState('');
	const [matLength, setMatLength] = useState('');
	const [actualLength, setActualLength] = useState('');
	const [percentYield, setPercentYield] = useState('');
	const [expectedDistanceFeet, setExpectedDistanceFeet] = useState('');
	const [expectedDistanceMiles, setExpectedDistanceMiles] = useState('');

	const regexNumbersOnly = /^[0-9\b]+$/;
	const elementsRef = useRef(inputs.map(() => createRef()));

	const handleInputUpdate = (index) => (e) => {
		const target = e.target;
		const value = target.value;
		if (regexNumbersOnly.test(value) || value === "") {
			updateInputs((inputs) =>
				inputs.map((item) =>
					item.id === index ? { ...item, value: value, error: false } : item
				)
			);
		}
	};

	const getInputValue = (name) => {
		return inputs.filter((x) => x.name === name)[0].value;
	}

	const calculate = () => {
		updateInputs((inputs) =>
			inputs.map((item) =>
				item.value === "" && item.required
					? { ...item, error: true }
					: { ...item, error: false }
			)
		);
		let counter = 0;
		inputs.forEach((item) => {
			if (item.value === "" && item.required) {
				counter++;
				setInputsValid(false);
				if(counter === 1) {
					elementsRef.current[item.id].current.focus();
				}
			}
		});
		if(inputsValid) {
			const totalPavingTime = getInputValue("TotalPavingTime");
			const productionRate = getInputValue("ProductionRate");
			const truckCapacity = getInputValue("TruckCapacity");
			const pavingThickness = getInputValue("PavingThickness");
			const pavingWidth = getInputValue("PavingWidth");
			const materialDensity = getInputValue("MaterialDensity");
			const finalDensity = getInputValue("FinalDensity");
			const actualLength = getInputValue("ActualLength");

			const pavingSpeed = ((1/((pavingThickness/12) * pavingWidth)) * (1/materialDensity) * (2000 * productionRate)/60).toFixed(1);
			const lengthOfMat = ((1/materialDensity) * (1/(pavingThickness/12*pavingWidth)) * (2000 * truckCapacity)).toFixed(1);
			const percentYield = (actualLength / lengthOfMat * 100).toFixed(1);
			const crossSectionalArea = pavingThickness / 12 * pavingWidth;
			const expectedDistanceFeet = (productionRate * 2000 / materialDensity / crossSectionalArea * totalPavingTime).toFixed(1);
			const expectedDistanceMiles = (expectedDistanceFeet / 5280).toFixed(1);
			setPavingSpeed(isNaN(pavingSpeed) ? 0 : pavingSpeed);
			setMatLength(isNaN(lengthOfMat) ? 0 : lengthOfMat);
			setActualLength(isNaN(actualLength) ? 0 : actualLength);
			setPercentYield(isNaN(percentYield) ? 0 : percentYield);
			setExpectedDistanceFeet(isNaN(expectedDistanceFeet) ? 0 : expectedDistanceFeet);
			setExpectedDistanceMiles(isNaN(expectedDistanceMiles) ? 0 : expectedDistanceMiles);
			setShowResult(true);
		}
	}

	const resetCalculator = () => {
		setShowResult(false);
		setPavingSpeed('');
		setMatLength('');
		setActualLength('');
		setPercentYield('');
		setExpectedDistanceFeet('');
		setExpectedDistanceMiles('');
		setInputsValid(false);
		updateInputs((inputs) =>
			inputs.map((item) => ({
				...item,
				value: "",
				error: false,
			}))
		);
	};

	useEffect(() => {
		setInputsValid(true);
		inputs.forEach((item) => {
			if (item.value === "" && item.required) {
				setInputsValid(false);
			}
		});
	}, [inputs]);

	return ReactDOM.createPortal(
		<div className="calculator">
			<div className="calculator__main">
				{inputs.map((item, index) => {
					return (
						<div
							className={`calculator__step ${item.value > 0}`}
							key={index}
						>
							<div className="calculator__text">
								<h3 id={`paving-label-${index}`}className="calculator__text-heading">
									{item.title}
								</h3>
								<p id={`paving-description-${index}`} className="calculator__text-description">
									{item.text}
								</p>
							</div>
							<div className="calculator__input-wrapper">
								<label className="calculator__label">
									<input
										type="text"
										name={item.name}
										ref={elementsRef.current[index]}
										aria-labelledby={`paving-label-${index}`}
										aria-describedby={`paving-description-${index}`}
										className="calculator__input"
										placeholder={item.placeholder}
										onChange={handleInputUpdate(index)}
										value={item.value}
										disabled={showResult ? 1 : 0}
										required={item.required}
									/>
									<span className="calculator__input-label">
										{item.label}
										{
											item.super && (
												<sup>{item.super}</sup>
											)
										}
									</span>
									{item.error && (
										<span className="calculator__error">{item.title} is required.</span>
									)}
								</label>
							</div>
						</div>
					);
				})}
			</div>
			<div className={`calculator__footer ${showResult}`} aria-live="polite">
				{
					showResult ? 
					<>
						<div className="calculator__footer-content">
							<h3 className="calculator__footer-heading calculator__footer-heading--secondary">
								Paving Speed
							</h3>
						</div>
						<div className="calculator__results">
							<span className="calculator__results-value">
								{pavingSpeed}
							</span>
							<span className="calculator__results-label">
								ft/min
							</span>
						</div></>
						: ''
				}
				<div className={`calculator__footer-content ${showResult ? `calculator__footer-content--secondary` : ''}`}>
					<h3 className="calculator__footer-heading calculator__footer-heading--secondary">
						{showResult ? <span>Yield</span> : <span>Paving Speed</span>}
					</h3>
				</div>
				{!showResult ? (
					<div className="calculator__results">
						<button
							type="button"
							className="calculator__footer-button button button--white"
							onClick={calculate}
						>
							Calculate
						</button>
					</div>
				) : (
					<div className="calculator__results calculator__results--secondary">
						{
							actualLength === ""
							?
							<div className="results-table">
								<p class="calculator__text calculator__text--wide">Actual Length is required to calculate yield.</p>
							</div>
							:
							<table className="results-table">
								<tbody>
									<tr className="results-table__row">
										<td className="results-table__cell results-table__cell--secondary">Length of Mat for One Truck at 100% Yield</td>
										<td className="results-table__cell results-table__cell--secondary">{matLength} feet</td>
									</tr>
									<tr className="results-table__row">
										<td className="results-table__cell results-table__cell--secondary">
											Percent Yield
										</td>
										<td className="results-table__cell results-table__cell--secondary">{percentYield} %</td>
									</tr>
									<tr className="results-table__row">
										<td className="results-table__cell results-table__cell--secondary">Expected Distance Per Day</td>
										<td className="results-table__cell results-table__cell--secondary">
											<span className="results-table__cell-item">{expectedDistanceFeet} feet</span>
											<span className="results-table__cell-item">{expectedDistanceMiles} miles</span>
										</td>
									</tr>
								</tbody>
							</table>
						}
						<button
							type="button"
							className="calculator__reset calculator__reset--secondary"
							onClick={resetCalculator}
						>
							<svg
								className="calculator__icon"
								aria-hidden="true"
								focusable="false"
								viewBox="0 0 13 16"
							>
								<use xlinkHref="#icon-reset"></use>
							</svg>
							Reset Calculator
						</button>
					</div>
				)}
				{
					showResult && <div><br></br><p><em>
					Due to changing operating and material conditions prevalent in any material processing, the shown figures should be considered as theoretical estimates only and are based on assumed input values provided by the customer. Astec, and its affiliate brands, make no guarantee of actual field results, and therefore, no production performance or warranty applies</em></p></div>
				}
			</div>
		</div>,
		containerElement
	);
};
export default PavingCalculator;
