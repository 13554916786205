import React from "react";

const Contact = (props) => {
  const roles = (window.astec)
    ? window.astec.roles
    : {"52f2d631-4c9b-4d03-8879-939cab3722ec": "Concrete Specialist",
    "84be4532-b71c-45cd-9e01-63063ffe15dd": "Controls",
    "dea664d3-2a30-4281-9570-8990a8898c1c": "Capital Equipment",
    "f9804930-91a0-48eb-9260-e452bbe32414": "Parts"};

  return (
    <div className="dealer">
      <div className="dealer__logo-heading">
        <svg
          className="dealer__logo dealer__logo--tertiary"
          aria-hidden="true"
          focusable="false"
          viewBox="0 0 52.4 41.7"
        >
          <use xlinkHref="#icon-logo-only"></use>
        </svg>
        <h2>Astec Contact</h2>
      </div>
      <ul className="dealer__list">
        {props.info.Contacts.map((item, index) => {
          return (
            <li className="dealer__card" key={index}>
              <div className="dealer__section dealer__section--secondary">
                <h3 className="dealer__heading dealer__heading--secondary">
                  {roles[item.RoleId]}
                </h3>
              </div>
              <div className="dealer__section">
                <div className="dealer__content dealer__content--wide">
                  <div className="dealer__detail">
                    <p className="dealer__text dealer__text--large">
                      {item.Name}
                    </p>
                    <p className="dealer__text dealer__text--large">
                      {item.Position}
                    </p>
                  </div>
                  {
                    item.Mobile 
                      ?
                      <div className="dealer__detail">
                        <p className="dealer__label">Mobile:</p>
                        <p className="dealer__text"><a href={`tel:${item.Mobile}`}>{item.Mobile}</a></p>
                      </div>
                      : ''
                  }
                  {
                    item.Email 
                      ?
                      <div className="dealer__detail">
                        <p className="dealer__label">Email:</p>
                        <p className="dealer__text">
                          <a href={`mailto:${item.Email}`}>
                            {item.Email}
										      </a>
                        </p>
                        
                      </div>
                      : ''
                  }
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Contact;
