import React, { useEffect, useState, useRef } from "react";
import useSWR from 'swr'
import FacilityMap from "./facility-map";
import ReactDOM from "react-dom";

const FacilityListing = (props) => {
	const containerElement = document.getElementById('facility-map');
	const api = "/sfapi/default/facilities?$expand=RelatedImage";
	// const api = "https://www.astec-local.com/sfapi/default/facilities?$expand=RelatedImage";
	const [isDesktop, updateScreenSize] = useState(true);
	const [activeMarker, updateActiveMarker] = useState(null);
	const resultsElement = useRef(null);
	const getLocations = () => 
		fetch(api)
			.then((response) => response.json());
	
	const { data, error } = useSWR(api, getLocations);

	// debouncer to throttle resize event
	const debounce = function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function later() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

	// show info window when clicking 'view more details'
	const showInfoWindow = (id) => {
		updateActiveMarker(id);
	}

	// scroll results to the active marker when clicking on a pin
	const setActiveMarker = (id) => {
		const item = document.getElementById(id);
		// const offset = item?.offsetTop || 0;
		// resultsElement.current.scrollTop = offset;
		updateActiveMarker(id);
	}

	useEffect(() => {
		getLocations();
		// check if we're on desktop to update expanded state on the facility items
		// on mobile, view more detail shows additional fields based on the expanded state
		// on desktop, it opens the info window
		window.addEventListener('resize', debounce(() => {
			const isDesktop = window.matchMedia('(min-width: 60em)').matches;
			updateScreenSize(isDesktop);
		},250));
	}, []);

	if(error) return "An error occured trying to load facility data.";
	if(!data) return "Loading Results...";

	return ReactDOM.createPortal(
		<div className="facility-listing">
			<div className="facility-listing__map">
				<FacilityMap locations={data.value} setActiveMarker={setActiveMarker} activeMarker={activeMarker}></FacilityMap>
			</div>
		</div>,
		containerElement
	)
};
export default FacilityListing;
