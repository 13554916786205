import React, { useState, useRef, createRef, useEffect } from "react";
import ReactDOM from "react-dom";

const HourlyTruckingCalculator = (props) => {
	const containerElement = document.getElementById(
		"hourly-trucking-calculator"
	);
	const [showResult, setShowResult] = useState(false);
	const [startTime, setStartTime] = useState("");
	const [nearestStartTime, setNearestStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [nearestEndTime, setNearestEndTime] = useState("");
	const [showDelay, setShowDelay] = useState(false);
	const [delayTimes, updateDelayTimes] = useState([]);
	const [timeError, setTimeError] = useState(false);
	const [inputsValid, setInputsValid] = useState(false);
	const regexNumbersOnly = /^[0-9\b]+$/;

	const [inputs, updateInputs] = useState([
		{
			id: 0,
			title: "Plant Production",
			text: "How much asphalt in tons per hour is going to be available for this project",
			label: "tons/hr",
			placeholder: "0",
			value: "",
			name: "PlantProduction",
			error: false,
		},
		{
			id: 1,
			title: "Truck Capacity",
			text: "Capacity of trucks used for this day",
			label: "tons",
			placeholder: "0",
			value: "",
			name: "TruckCapacity",
			error: false,
		},
		{
			id: 2,
			title: "Load Time",
			text: "Time taken to load a truck at the asphalt plant",
			label: "min",
			type: "number",
			label: "min",
			placeholder: "00",
			value: "",
			name: "LoadTime",
			error: false,
		},
		{
			id: 3,
			title: "Tarp",
			text: "Time taken to tarp the load in the truck",
			label: "min",
			placeholder: "00",
			value: "",
			name: "Tarp",
			error: false,
		},
		{
			id: 4,
			title: "Haul to Site",
			text: "Best achievable haul time to project site, any possible delays are added below",
			label: "min",
			placeholder: "00",
			value: "",
			name: "HaulToSite",
			error: false,
		},
		{
			id: 5,
			title: "Wait on Site",
			text: "Time truck spends on site waiting to dump",
			label: "min",
			placeholder: "00",
			value: "",
			name: "WaitOnSite",
			error: false,
		},
		{
			id: 6,
			title: "Engaged with Hopper",
			text: "Time truck spends engaged with hopper of paver or MTV",
			label: "min",
			placeholder: "00",
			value: "",
			name: "EngagedWithHopper",
			error: false,
		},
		{
			id: 7,
			title: "Clean",
			text: "Time truck spends cleaning off after dumping",
			label: "min",
			placeholder: "00",
			value: "",
			name: "Clean",
			error: false,
		},
		{
			id: 8,
			title: "Return Haul",
			text: "Best achievable haul time back to plant, any possible delays are added below",
			label: "min",
			placeholder: "00",
			value: "",
			name: "ReturnHaul",
			error: false,
		},
	]);

	const elementsRef = useRef(inputs.map(() => createRef()));
	const startTimeRef = useRef();
	const endTimeRef = useRef();
	const handleInputUpdate = (index) => (e) => {
		const target = e.target;
		const value = target.value;
		if (regexNumbersOnly.test(value) || value === "") {
			updateInputs((inputs) =>
				inputs.map((item) =>
					item.id === index ? { ...item, value: value, error: false } : item
				)
			);
		}
	};

	const handleTimeUpdate = (e) => {
		// hours is returned in 0 - 23 format
		const target = e.target;
		const values = target.value.split(":");
		const name = target.name;
		const minutes = Number(values[1]);
		const hour = Number(values[0]);
		const nearestHour = minutes > 30 ? hour + 1 : hour;
		if (name === "start") {
			setStartTime(target.value);
			setNearestStartTime(nearestHour);
		} else if (name === "end") {
			setEndTime(target.value);
			setNearestEndTime(nearestHour);
		}
	};

	const toggleDelays = (e) => {
		if (nearestStartTime !== "" && nearestEndTime !== "") {
			setTimeError(false);
			setShowDelay(!showDelay);
		} else {
			setTimeError(true);
			if(nearestStartTime === "") {
				startTimeRef.current.focus();
			} else if(nearestEndTime === "") {
				endTimeRef.current.focus();
			}
		}
	};

	const handleDelayUpdate = (index) => (e) => {
		const target = e.target;
		const value = target.value;
		const name = target.name;
		if (name === "departure") {
			updateDelayTimes((times) =>
				times.map((item) =>
					item === times[index] ? { ...item, departure: value } : item
				)
			);
		} else {
			updateDelayTimes((times) =>
				times.map((item) =>
					item === times[index] ? { ...item, return: value } : item
				)
			);
		}
	};

	const calculateTimeValue = (hour) => {
		// get plant production and truck capacity values from the inputs state
		const plantProduction = Number(
			inputs.filter((x) => x.title === "Plant Production")[0].value
		);
		const truckCapacity = Number(
			inputs.filter((x) => x.title === "Truck Capacity")[0].value
		);
		// get departure and return times from the delaytimes state
		const departureTime = Number(
			delayTimes.filter((x) => x.id === hour)[0].departure
		);
		const returnTime = Number(
			delayTimes.filter((x) => x.id === hour)[0].return
		);
		// sum all the minute input values
		let timeSum = 0;
		inputs.forEach((item) => {
			if (item.label === "min") {
				timeSum += Number(item.value);
			}
		});
		const sumTotal = timeSum + departureTime + returnTime;
		const timeValue = plantProduction / 60 / (truckCapacity / sumTotal);
		return timeValue;
	};

	const calculateTrucks = (hour) => {
		const endTimeSecondary =
			nearestStartTime <= nearestEndTime
				? nearestEndTime
				: nearestStartTime + 24;
		if (nearestEndTime === endTimeSecondary) {
			if (hour >= nearestStartTime && hour < endTimeSecondary) {
				const value = calculateTimeValue(hour);
				return isNaN(value) ? 0 : value.toFixed(1);
			} else {
				return 0;
			}
		} else {
			if (hour >= nearestStartTime || hour < nearestEndTime) {
				const value = calculateTimeValue(hour);
				return isNaN(value) ? 0 : value.toFixed(1);
			} else {
				return 0;
			}
		}
	};

	const calculate = () => {
		// all fields are required except delay times
		if (nearestStartTime !== "" && nearestEndTime != "") {
			setTimeError(false);
			if (inputsValid) {
				updateDelayTimes((times) =>
					times.map((item) => ({
						...item,
						trucks: calculateTrucks(Number(item.id)),
					}))
				);
				setShowResult(true);
			}
		} else {
			setTimeError(true);
		}
		updateInputs((inputs) =>
			inputs.map((item) =>
				item.value === "" || item.value === 0
					? { ...item, error: true }
					: { ...item, error: false }
			)
		);
		let counter = 0;
		inputs.forEach((item) => {
			if (item.value === "") {
				counter++;
				setInputsValid(false);
				if(counter === 1) {
					elementsRef.current[item.id].current.focus();
				}
			}
		});
		// Formula
		// =IF(K21=L21,IF(AND(F26>=K20,F26<L21),F33,0),IF(OR(F26>=K20,F26<K21),F33,0))
		/* if the end time === (if(start time <= end time) {endtime else endtime + 24}
				if the current time hour >= start time && current time hour < end time {
					return plant production / 60 (trucky capacity / sum(load time to return haul, departure, return time))
				} else {
					0
				}
			else if they are not the same  {
				if(current time hour >= start time || current time hour < end time) {
					if the current time hour >= start time && current time hour < end time {
						return plant production / 60 (trucky capacity / sum(load time to return haul, departure, return time))
					} else {
						0
					}
				}
			}
		*/
	};

	const resetCalculator = () => {
		setShowResult(false);
		setStartTime("");
		setNearestStartTime("");
		setEndTime("");
		setNearestEndTime("");
		setShowDelay(false);
		updateDelayTimes([]);
		setTimeError(false);
		setInputsValid(false);
		updateInputs((inputs) =>
			inputs.map((item) => ({
				...item,
				value: "",
				error: false,
			}))
		);
	};

	useEffect(() => {
		setInputsValid(true);
		inputs.forEach((item) => {
			if (item.value === "") {
				setInputsValid(false);
			}
		});
	}, [inputs]);

	useEffect(() => {
		if( nearestStartTime && nearestEndTime) {
			let startTime = nearestStartTime;
			const range = (nearestStartTime === nearestEndTime)
				? [nearestStartTime] : [];
			if(nearestStartTime !== nearestEndTime) {
				for (let i = 0; i < 24; i++) {
					range.push(startTime);
					startTime++;
					if (startTime === nearestEndTime) {
						break;
					} else if (startTime === 25) {
						startTime = 1;
					}
				}
			}
			updateDelayTimes((times) =>
				times.filter((x) => range.indexOf(x.time) > -1)
			);
			const delayData = range.map((hour) => {
				const formattedTime =
					hour < 13
						? ("0" + hour).slice(-2) + ":00 AM"
						: hour - 12 + ":00 PM";
				return {
					id: hour,
					time: formattedTime,
					departure: "",
					return: "",
					trucks: 0,
				};
			});
			updateDelayTimes((times) => [...times, ...delayData]);
		}
	}, [nearestStartTime, nearestEndTime]);

	return ReactDOM.createPortal(
		<div className="calculator">
			<div className="calculator__main">
				{inputs.map((item, index) => {
					return (
						<div
							className={`calculator__step ${item.value > 0}`}
							key={index}
						>
							<div className="calculator__text">
								<h3 id={`item-label-${index}`} className="calculator__text-heading">
									{item.title}
								</h3>
								<p id={`item-description-${index}`}className="calculator__text-description">
									{item.text}
								</p>
							</div>
							<div className="calculator__input-wrapper">
								<label className="calculator__label">
									<input
										type="text"
										ref={elementsRef.current[index]}
										name={item.name}
										aria-labelledby={`item-label-${index}`}
										aria-describedby={`item-description-${index}`}
										className="calculator__input"
										placeholder={item.placeholder}
										onChange={handleInputUpdate(index)}
										value={item.value}
										disabled={showResult ? 1 : 0}
										required
									/>
									<span className="calculator__input-label">
										{item.label}
									</span>
									{item.error && (
										<span className="calculator__error">{item.title} is required.</span>
									)}
								</label>
							</div>
						</div>
					);
				})}
				<div
					className={`calculator__step ${
						nearestStartTime !== "" && nearestEndTime !== ""
					}`}
				>
					<div className="calculator__text">
						<h3 className="calculator__text-heading">
							Start &amp; End Time
						</h3>
						<p className="calculator__text-description">Enter the time, to the closest hour, you are going to start and end paving</p>
					</div>
					<div className="calculator__input-wrapper calculator__input-wrapper--secondary">
						<label className="calculator__label-inline calculator__label-inline--secondary">
							<input
								className="calculator__input calculator__input--secondary"
								type="time"
								name="start"
								ref={startTimeRef}
								placeholder="06:00 AM"
								value={startTime}
								onChange={handleTimeUpdate}
								required
								aria-describedby="time-error"
								disabled={showResult ? 1 : 0}
							/>
							<span className="calculator__input-label">
								start
							</span>
						</label>
						<label className="calculator__label-inline calculator__label-inline--secondary">
							<input
								className="calculator__input calculator__input--secondary"
								type="time"
								name="end"
								ref={endTimeRef}
								placeholder="12:00 PM"
								required
								aria-describedby="time-error"
								onChange={handleTimeUpdate}
								value={endTime}
								disabled={showResult ? 1 : 0}
							/>
							<span className="calculator__input-label">end</span>
						</label>
						<div className="calculator__error" aria-live="polite">
							{timeError && (
								<span id="time-error">Start and End times are required</span>
							)}
						</div>
					</div>
				</div>

				<div
					className={`calculator__step calculator__step--clear ${
						nearestStartTime !== "" && nearestEndTime !== ""
					}`}
				>
					<div className="calculator__text">
						<h3 className="calculator__text-heading">Delay Time</h3>
						<p className="calculator__text-description">Delay time allows you to enter the time in minutes that a truck will be delayed from its best haul time, either to the site or returning from the site.</p>
					</div>
					<div className="calculator__input-wrapper">
						<button
							type="button"
							className={`calculator__toggle ${showDelay}`}
							onClick={toggleDelays}
						>
							<span>Add Delay Time</span>
						</button>
					</div>
					<ul className={`calculator__delay-list ${showDelay}`}>
						{delayTimes.map((item, index) => {
							return (
								<li
									className="calculator__delay-item"
									key={index}
								>
									<h3 className="calculator__delay-heading">
										{item.time}
									</h3>
									<label className="calculator__delay-label">
										Departure delay
										<input
											type="text"
											className="calculator__input calculator__input--delay"
											placeholder="00"
											value={item.departure}
											name="departure"
											onChange={handleDelayUpdate(index)}
											disabled={showResult ? 1 : 0}
										/>
										<span className="calculator__input-label">
											min
										</span>
									</label>
									<label className="calculator__delay-label">
										Return delay
										<input
											type="text"
											className="calculator__input calculator__input--delay"
											name="return"
											placeholder="00"
											value={item.return}
											onChange={handleDelayUpdate(index)}
											disabled={showResult ? 1 : 0}
										/>
										<span className="calculator__input-label">
											min
										</span>
									</label>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<div className={`calculator__footer ${showResult}`}aria-live="polite">
				<div className="calculator__footer-content">
					<h3 className="calculator__footer-heading">
						Trucks Needed
					</h3>
				</div>
				{!showResult ? (
					<div className="calculator__results">
						<button
							type="button"
							className="calculator__footer-button button button--white"
							onClick={calculate}
						>
							Calculate
						</button>
					</div>
				) : (
					<div className="calculator__results">
						<table className="results-table">
							<tbody>
								<tr className="results-table__row results-table__row--header">
									<th className="results-table__header">
										Time
									</th>
									<th className="results-table__header">
										Trucks
									</th>
								</tr>
								{delayTimes.map((item, index) => {
									return (
										<tr
											className="results-table__row"
											key={index}
										>
											<td className="results-table__cell">
												<span>{item.time}</span>
											</td>
											<td className="results-table__cell results-table__cell--large">
												<span>{item.trucks}</span>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<button
							type="button"
							className="calculator__reset calculator__reset--secondary"
							onClick={resetCalculator}
						>
							<svg
								className="calculator__icon"
								aria-hidden="true"
								focusable="false"
								viewBox="0 0 13 16"
							>
								<use xlinkHref="#icon-reset"></use>
							</svg>
							Reset Calculator
						</button>
					</div>
				)}
				{
					showResult && <div><br></br><p><em>
					Due to changing operating and material conditions prevalent in any material processing, the shown figures should be considered as theoretical estimates only and are based on assumed input values provided by the customer. Astec, and its affiliate brands, make no guarantee of actual field results, and therefore, no production performance or warranty applies</em></p></div>
				}
			</div>
		</div>,
		containerElement
	);
};
export default HourlyTruckingCalculator;
