import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import queryString from "query-string";
import { formatUnitText } from "../helpers/helpers";

const ProductCompareTable = (props) => {
	const containerElement = document.getElementById('product-compare-table');
	const productId = containerElement.getAttribute("data-id");
	// const productId = "90422bda-6a3d-4b0e-a08c-0ad4622e3e87";
	const localStorageUnit = localStorage.getItem('unitSystem');
	const [filterExpanded, setFilterExpanded] = useState(false);
	const [compareAll, setCompareAll] = useState(true);
	const [products, updateProducts] = useState({});
	const [models, updateModels] = useState([]);
	const [specFields, updateSpecFields] = useState([]);
	const [unitSystem, setUnitSystem] = useState(localStorageUnit ? localStorageUnit : "imperial");
	const [showUnits, updateShowUnits] = useState(false);
	const [tableType, setTableType] = useState('general'); // general or compare
	// api/elastic/byid?id=90422bda-6a3d-4b0e-a08c-0ad4622e3e87&indexName=products_637740782858594231
	// const apiLocal = "https://www.astec-local.com/api/elastic/byid?id=90422bda-6a3d-4b0e-a08c-0ad4622e3e87&indexName=products_637741433496176526";
	const indexName = queryString.parse(window?.location?.search)?.indexName;
	const toggleRef = useRef();
	const compareTableRef = useRef();
	useOnClickOutside(toggleRef, () => setFilterExpanded(false));
	const url = `/api/elastic/byid?id=${productId}${indexName ? `&indexName=${indexName}` : ""}`;
	const fetchModels = function() {
		fetch(url)
			.then((response) => {
				return response.json()
			})
			.then(data => {
				updateProducts(data);
				const newFields = [];
				data.models.map((model) => {
					model.specs.map((spec) => {
						if(spec.type === "MEASUREMENT") {
							updateShowUnits(true);
						}
						if(newFields.some((field) => field.label === spec.label)) {
						} else {
							newFields.push(spec);
						}
					})
				});
				updateSpecFields(newFields);
				const newModels = data.models.map((item) => ({
					...item,
					checked: data.models.length > 1,
				}));
				// Create a new model state so we can filter/display through items easily
				updateModels(newModels);
			})
			.catch(error => {
				console.error("Error retrieving product models", error);
			});
	}

	// Get the lowest or highest value from measurements and number spec fields so we can display a range on the general table view
	const getRangeValue = (field,range) => {
		let lowestValue = Number.MAX_SAFE_INTEGER;
		let highestValue = 0;
		const type = field.type;
		const unit = (type === 'MEASUREMENT') ? formatUnitText(field.units[unitSystem]) : '';
		if(type === 'MEASUREMENT' || type === 'NUMBER') {
			products.models.forEach(model => {
				model.specs.forEach(spec => {
					if(spec.label === field.label) {
						const specValue = (type === 'NUMBER')
							? spec.value
							: (unitSystem === 'imperial')
							? spec.value.imperial
							: spec.value.metric;
						if(range === 'min') {
							lowestValue = (specValue < lowestValue) ? specValue : lowestValue;
						} else if(range === 'max') {
							highestValue = (specValue > highestValue) ? specValue : highestValue;
						}
					}
				})
			});
			if(range === 'min') {
				return lowestValue.toLocaleString() + ' ' + unit;
			} else if(range === 'max') {
				return highestValue.toLocaleString() + ' ' + unit;
			}
		}
	}

	// Change the field type display text for the general view table
	const getGeneralValue = (type) => {
		switch(type) {
			case 'YESNO':
				return 'Yes/No';
			case 'CLASSIFICATION':
				return '--';
			case 'TEXT':	
				return '--';
			default:
				break;
		}
	};

	// return range or general value depending on field type
	const getFieldValue = (field, modelCount, specIndex) => {
		if (field.type === 'NUMBER') {
			const minValue = getRangeValue(field, 'min');
			const maxValue = getRangeValue(field, 'max');
			if (modelCount === 1) {
				return minValue;
			} else {
				return `${minValue} - ${maxValue}`;
			}
		} else if(field.type === 'MEASUREMENT') {
			// for measurements, return the free text field if its filled out for a single model view only
			// on the range view, return -- for a single model
			// however, if there are numeric values, display the range of those
			const textUnitType = unitSystem + "Text";
			if (modelCount === 1) {
				if (field.value[textUnitType] !== "") {
					return field.value[textUnitType];
				} else {
					return field.value[unitSystem] + formatUnitText(field.units[unitSystem]);
				}
			} else {
				const minValue = getRangeValue(field, 'min');
				const maxValue = getRangeValue(field, 'max');
				if (field.value[textUnitType] !== "") {
					const maxNumeric = Number(maxValue.split(' ')[0]);
					// if max is greater than 0 there must be values in the numeric that can we can show for range
					// else this is just a free text field with no numeric values so display --
					if (maxNumeric > 0) {
						return `${minValue} - ${maxValue}`;
					} else {
						return "--";
					}
				} else {
					return `${minValue} - ${maxValue}`;
				}
			}
		} else {
			// For text, classifications and yes/no
			if (modelCount === 1) {
				return models[0].specs[specIndex].value.toLocaleString();
			} else {
				return getGeneralValue(field.type)
			}
		}
	}

	// When a model is checked, set it to checked
	// The apply filter will then check every model and display only the checked ones
	const addFilter = (e) => {
		const target = e.target;
		const modelName = target.value;
		if(target.checked) {
			updateModels((models) => (
				models.map((item) => item.name === modelName ? {
					...item,
					checked: true,
				}
				: item
				)));
		} else {
			updateModels((models) => (
				models.map((item) => item.name === modelName ? {
					...item,
					checked: false,
				}
				: item
				)));
		}
	}

	const selectAllFilters = function(e) {
		const target = e.target;
		setCompareAll(target.checked);
		if(target.checked) {
			updateModels((models) => (
				models.map((item) => ({
					...item,
					checked: true,
				}))
			))
		}
	};

	// reset selected models to unchecked and not visible
	const clearFilter = (e) => {
		const target = e.target;
		const targetName = target.getAttribute('data-name');
		// Firefox causes visual display issue on table re render, forcing style change seems to fix this
		compareTableRef.current.style.opacity = '.9';
		setTimeout(() => {
			compareTableRef.current.style.opacity = '1';
		},0);
		const visibleModels = models.filter((model) => model.checked);
		// We are about to remove the last model from the table
		// so uncheck the compare all checkbox

		if(visibleModels.length === 1) {
			setCompareAll(false);
		}
		updateModels((models) => models.map(item => item.name === targetName ? {
			...item,
			checked: false,
		}
		: item
		));
	}
	
	// reset all models
	const clearAllFilters = (e) => {
		setCompareAll(false);
		updateModels((models) => models.map((item) => ({
			...item,
			checked: false,
		})));
	}

	// initial data fetch
	useEffect(() => {
		fetchModels();
	},[]);

	// When deselecting models, set the table back to the general view when no models are selected
	useEffect(() => {
		setTableType('general');
		models.forEach((item) => {
			if(item.checked) {
				setTableType('compare');
			}
		});
	},[models]);

	return ReactDOM.createPortal(
		<div className="model-compare">
			{
				models.length > 1 &&
				<h3 className="model-compare__heading">Model</h3>
			}
			{
				models.length > 1 &&
				<div className="model-compare__filter-dropdown" ref={toggleRef}>
					<button type="button" className={`model-compare__toggle ${filterExpanded}`} onClick={() => setFilterExpanded(!filterExpanded)} aria-expanded={filterExpanded}>Compare Models</button>
					<ul className="model-compare__dropdown">
						<li className="model-compare__item" key="compare-all">
							<label className="model-compare__label form__label form__label--flex">
								<input type="checkbox" className="form__input-checkbox form__input-checkbox--secondary" value="compare-all" onChange={selectAllFilters} checked={compareAll}/>
								<span className="form__pseudo-checkbox"></span>
								Compare All
							</label>
						</li>
						{
							models.sort((a,b) => a.order - b.order).map((item,index) => {
								return (
									<li className="model-compare__item" key={index}>
										<label className="model-compare__label form__label form__label--flex">
											<input type="checkbox" className="form__input-checkbox form__input-checkbox--secondary" value={item.name} onChange={addFilter} checked={item.checked} />
											<span className="form__pseudo-checkbox"></span>
											{item.name}
										</label>
									</li>
								);
							})
						}
					</ul>
				</div>
			}
			{
				tableType === 'compare' &&
				<ul className="model-compare__filters">
					{
						models.map((item,index) => {
							if(item.checked) {
								return (
									<li className="model-compare__filter-item" key={index}>
										<button type="button" className="model-compare__filter-button" onClick={clearFilter} data-name={item.name}>{item.name}</button>
									</li>
								);
							}
						})
					}
					{
						<li className="model-compare__filter-item model-compare__filter-item--secondary" key="clear-all">
							<button type="button" className="model-compare__filter-button model-compare__filter-button--secondary" onClick={clearAllFilters}>Clear All</button>						
						</li>
					}
				</ul>
			}
			<div className="model-compare__table spec-tables__container">
				<table className="compare-table" ref={compareTableRef}>
					<thead>
						<tr>
							<th>Specification</th>
							{
								(tableType === 'general' && models.length > 1) && <th>Range</th>
							}
							{
								(tableType === 'general' && models.length === 1) && <th>Key Specs</th>
							}
							{
								tableType === 'compare' && models.map((model) => {
									if(model.checked) {
										return (
											<th key={model.name}>{model.name}</th>
										);
									}
								})
							}
						</tr>
					</thead>
					<tbody>
						{
							specFields?.map((field,index) => {
									return(
								<tr key={field.field}>
									<td data-title="Specification">
										{field.label}
									</td>
									{
										tableType === 'general' &&
										<td data-title="General Value">
											<span className="compare-table__value">{getFieldValue(field, models.length, index)}</span>
										</td>
									}
									{
										tableType === 'compare' &&
										models.map((model) => {
											if(model.checked) {
												const specValue = model.specs.find((spec) => spec.label === field.label);
												if(specValue) {
													if(field.type === 'MEASUREMENT') {
														const unitSystemText = unitSystem + "Text";
														return (
															<td data-title={field.label} key={model.name}>
																{specValue.value[unitSystemText] !== "" ? specValue.value[unitSystemText] : specValue.value[unitSystem].toLocaleString()} 
																{specValue.value[unitSystemText] === "" && formatUnitText(field.units[unitSystem])}
															</td>
														)
													} else if(field.type === 'YESNO') {
														return (
															<td data-title={field.label} key={model.name}>
																{
																	specValue.value ? 'Yes' : 'No'
																}
															</td>
														)
													} else {
														return (
															<td data-title={field.label} key={model.name}>{specValue.value.toLocaleString()}</td>
														)
													} 
												} else {
													return (
														<td key={model.name}>--</td>
													)
												}
											}
										})
									}
								</tr>
								);
							})
						}
					</tbody>
				</table>
			</div>
			{
				showUnits &&
				<div className="spec-tables__toggle">
					<div className="spec-tables__label">
						<div className="spec-tables__units">UNITS:</div> 
						<button type="button" className={unitSystem === 'metric' ? 'active' : ''} data-units="metric" onClick={() => setUnitSystem('metric')}>Metric (mm)</button>
						<button type="button" className={ unitSystem === 'imperial' ? 'active' : ''} data-units="imperial" onClick={() => setUnitSystem('imperial')}>Imperial (in)</button>				
					</div>
				</div>
			}
		</div>,
		containerElement
	)
};
export default ProductCompareTable;


function useOnClickOutside(ref, handler) {
	useEffect(
	  () => {
		const listener = (event) => {
		  // Do nothing if clicking ref's element or descendent elements
		  if (!ref.current || ref.current.contains(event.target)) {
			return;
		  }
		  handler(event);
		};
		document.addEventListener("click", listener);
		return () => {
		  document.removeEventListener("click", listener);
		};
	  },
	  [ref, handler]
	);
}