import React from "react";
import LocationMap from "./locationMap";

const Dealer = (props) => {
	return (
		<div className={`dealer ${props.info.Manager ? "" : 'dealer--secondary'}`}>
			<div className="dealer__card">
				<div className="dealer__container">
					<div className="dealer__section dealer__section--inner">
						{props.info.Logo ? (
							<img
								src={props.info.Logo}
								alt="dealer logo"
								className="dealer__logo"
							/>
						) : (
							""
						)}
						<div className={`dealer__content ${props.info.Logo ? '' : 'dealer__content--wide'}`}>
							<h3 className="dealer__heading">
								{props.info.Name}
							</h3>
							<a
								href={props.info.Link}
								target="_blank"
								rel="noreferrer noopener"
								className="button dealer__link"
							>
								Dealer Website
							</a>
							<div className="dealer__detail">
								<p className="dealer__label">Address:</p>
								<p className="dealer__text">
									{props.info.Address?.Street &&
										props.info.Address.Street + " "}
									{props.info.Address?.City &&
										props.info.Address.City + ", "}
									{props.info.Address?.StateCode &&
										props.info.Address.StateCode + " "}
									{props.info.Address?.Zip &&
										props.info.Address.Zip}
								</p>
							</div>
							{props.info.Phone ? (
								<div className="dealer__detail dealer__detail--inline">
									<p className="dealer__label">Phone:</p>
									<p className="dealer__text">
										<a href={`tel:${props.info.Phone}`}>
											{props.info.Phone}
										</a>
									</p>
								</div>
							) : (
								""
							)}
							{props.info.Email ? (
								<div className="dealer__detail dealer__detail--inline">
									<p className="dealer__label">Email:</p>
									<p className="dealer__text">
										<a href={`mailto:${props.info.Email}`}>
										{props.info.Email}
										</a>
									</p>
								</div>
							) : (
								""
							)}
						</div>
            {
              props.info.Manager &&
              <div className="dealer__border"></div>
            }
					</div>

					{props.info.Manager ? (
						<div className="dealer__section dealer__section--inner">
							<h3 className="dealer__sub-heading">
								Astec Contact
							</h3>
							<img
								className="dealer__logo dealer__logo--secondary"
								src="/ResourcePackages/Talon/assets/dist/images/logo-vertical.png"
								alt="astec logo"
							></img>
							<div className="dealer__content">
								<div className="dealer__detail">
									<p className="dealer__text">
										{props.info.Manager.Name}
									</p>
									<p className="dealer__text">
										{props.info.Manager.Position}
									</p>
								</div>
								<div className="dealer__detail dealer__detail--inline">
									<p className="dealer__label">
										{props.info.Manager.Mobile
											? "Mobile:"
											: ""}
									</p>
									<p className="dealer__text">
										<a href="tel:{props.info.Manager.Mobile}">
											{props.info.Manager.Mobile}
										</a>
									</p>
								</div>
								<div className="dealer__detail">
									<p className="dealer__label">
										{props.info.Manager.Email
											? "Email:"
											: ""}
									</p>
									<p className="dealer__text">
										<a href={`mailto:${props.info.Manager.Email}`}>
											{props.info.Manager.Email}
										</a>
									</p>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</div>
				<div className="dealer__map">
					<LocationMap
						locations={props.info.Locations}
						dealer={props.info}
					></LocationMap>
				</div>
			</div>

			<ul className="dealer__list">
				{props.info.Locations.map((item, index) => {
					return (
						<li className="dealer__card" key={index}>
							<div className="dealer__container dealer__container--secondary">
								<div className="dealer__section">
									<h3 className="dealer__heading">
										{item.Title}
									</h3>
									<div className="dealer__aside">
										<p className="dealer__distance">
											{item.DistanceInMiles
												? item.DistanceInMiles +
												  " miles"
												: ""}
										</p>
										<a
											href={`https://www.google.com/maps/search/?api=1&query=${item.Address?.Latitude},${item.Address?.Longitude}`}
											className="dealer__link"
											target="_blank"
											rel="noopener noreferrer"
										>
											<svg
												className="dealer__icon"
												aria-hidden="true"
												focusable="false"
												viewBox="0 0 32 32"
											>
												<use xlinkHref="#icon-map2"></use>
											</svg>
											<span>Get Directions</span>
										</a>
									</div>
									<div className="dealer__detail">
										<p className="dealer__label">
											Address:
										</p>
										<p className="dealer__text">
											{item.Address?.Street &&
												item.Address.Street + " "}
											{item.Address?.City &&
												item.Address.City + ", "}
											{item.Address?.StateCode &&
												item.Address.StateCode + " "}
											{item.Address?.CountryCode}
										</p>
									</div>
									<div className="dealer__detail">
										<p className="dealer__label">Phone:</p>
										<p className="dealer__text">
											{item.Phone}
										</p>
									</div>
									<div className="dealer__detail">
										<p className="dealer__label dealer__label--secondary">
											Products:
										</p>
										<p className="dealer__text">
											{item.ProductIds.map(
												(product, index) => {
													return (
														<span
															className="dealer__product"
															key={product}
														>
															{
																props
																	.productLines[
																	product
																]
															}
														</span>
													);
												}
											)}
										</p>
									</div>
								</div>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default Dealer;
